
import Node from "./node"

class Equalizer extends Node {

	init() {
		const ctx = this.ctx
		

		this.node = ctx.createGain()
		this.outNode = ctx.createGain()

		const FR = [32, 64, 125, 250, 500, 1000, 2000, 4000, 8000, 16000]

		var bands = Object.values(this.config.bands)

		const nodes = []

		const setNodeProps = (node, band, idx) => {
			node.type = (idx == 0) ? "lowshelf" : ((idx == bands.length - 1) ? "highshelf" : "peaking")
			const fi = Math.round((idx / bands.length) * 10)
			node.frequency.value = FR[fi] || 16000
			node.gain.value = band.gain
			node.Q.value = 1
			//console.log("eq band", node, fi)
		}
		const updateNode = (band, idx) => {
			const node = nodes[idx]
			setNodeProps(node, band, idx)
		}
		const createNodes = () => {
			this.remove()
			bands.forEach((band, idx) => {
				const node = ctx.createBiquadFilter()
				//this.inp.connect(node)
				const inp = nodes[idx-1] || this.node
				inp.connect(node)

				nodes.push(node)
				updateNode(band, idx)
			})
			nodes[nodes.length-1].connect(this.outNode)
			this.nodes = nodes
		}

		this.reset = () => {
			bands = Object.values(this.config.bands)
			//createNodes()
			if (bands.length !== nodes.length) {
				createNodes()
			} else {
				bands.forEach(updateNode)
			}
		}

		this.remove = () => {
			this.node.disconnect()
			if (nodes.length) {
				for (let node of nodes) {
					node.disconnect()
				}
				nodes.length = 0
			}
		}

		createNodes()

	}

	get output() {
		return this.outNode
	}
	get input() {
		return this.node
	}

	connect(sources) {
		this.source = sources[0]
		this.source.output.connect(this.input)
	}


}

export default Equalizer
