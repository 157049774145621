import { Element, html, css } from "../../element";
import ScrollAnimation from "../../../../utils/ScrollTimeline";

const styles = css`
  :host {
    display: block;
    padding: 0;
    width: 100vw;
    height: 200vh;
  }
  .container {
    width: 100vw;
    height: 100vh;
	pointer-events: all;
	position: sticky;
	top: 0;
	left: 0;
	border: 1px solid red;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center
  }
`;

const template = function (elem) {
	return html`
		<div class="container "> 
			<div class="content">
				<div class="content-container">
					section
				</div>
			</div>
		</div>
	`
};


export class Section extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    type: { type: String },
	content: { type: Object, }
  };

  constructor() {
    super();   
  }

  onClick(e) {
	console.log("clicked", e);
  }

  attachedCallback() {

	const ctr = this.qs('.content-container');

	const tl = ScrollAnimation(this, { debug: false, start: 'top center', end: 'bottom bottom' });
	tl.fromTo(ctr, { opacity: 0, translateX: 300}, 
			{ opacity: 1, translateX: 0, ease: "power1.inOut" }, 0.25);


	console.log("section attached", this.content);
  }


}

customElements.define("harmony-three-section", Section);
