import { Element, html, css } from "../element";


const styles = css`
  :host {
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
  }
  .content {
    width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
  .canvas {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
`;

const template = function (elem) {
	return html`
		<div class="container "> 
			<div class="content">
				<canvas class="canvas" id="canvas"></canvas>
			</div>
		</div>
	`
};



export class Stage extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    type: { type: String },
	context: { type: Object, attribute: true },
  };

  constructor() {
    super();   

	this._context = {};
  }

  set context(data) {
	console.log("context has been set", data)
	Object.assign(this._context, data);
  }

  get context() {
	return this._context
  }

  onClick(e) {
	console.log("clicked", e);
  }


  async attachedCallback() {
	const canvas = this.qs("#canvas");

	const setupStage = (await import("/harmony.three.bundled.js")).default;


	this.context = {
		window: {
			width: window.innerWidth,
			height: window.innerHeight,
		},
		mouse: {
			x: 0, y: 0
		},
		pointer: {
			x: 0, y: 0
		}
	}

	const { context } = this;

	
	const { render, updateSize, onMove, onClick } = setupStage(canvas, context, this)

	const onPointerMove = (event) => {
		if ( event.isPrimary === false ) return;

		context.mouse.x = event.clientX;
		context.mouse.y = event.clientY;

		context.mouse.cx = event.clientX - context.window.width / 2;
		context.mouse.cy = event.clientY - context.window.height / 2;

		// calculate pointer position in normalized device coordinates
		// (-1 to +1) for both components

		context.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
		context.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

		onMove()
	}


	const onPointerDown = (event) => {
		if ( event.isPrimary === false ) return;


		onClick()
	}

	

	const resizeHandler = () => {
		updateSize()
	}

	window.addEventListener("resize", resizeHandler, false);

	document.body.style.touchAction = 'none';
	document.body.addEventListener( 'pointermove', onPointerMove );

	document.body.addEventListener( 'pointerdown', onPointerDown );

	let rafid = null;
	var lastTime;
	var requiredElapsed = 1000 / 10; // desired interval is 10fps

	function loop(now) {
		
		rafid = requestAnimationFrame(loop);
		
		if (!lastTime) { lastTime = now; }
		var elapsed = now - lastTime;

		if (elapsed > requiredElapsed) {
			// do stuff
			lastTime = now;
		}

		context.delta = elapsed;

		render(now, elapsed);		
	}



	rafid = requestAnimationFrame(loop);



  }

}

customElements.define("harmony-stage", Stage);
