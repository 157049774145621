import Node from "./node"

class Gain extends Node {

	init() {
		const ctx = this.ctx
		
		var gain = this.ctx.createGain()
		gain.gain.value = this.config.gain || 1

		this.node = gain

		this.reset = () => {
			gain.gain.value = this.config.gain
		}

	}

	set gain(value) {
		this.node.value = value
	}

}

export default Gain
