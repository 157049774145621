import { LitElement, html, css, svg } from "lit-element";
import createAudioBuffer from "../audio/audiobuffer";


const styles = css`
  :host {
    display: inline-block;
    padding: 0;
  }
`;

const template = function (elem) {
  return html`
    <div class="container"> 
      no template set
    </div>
  `;
};


class Element extends LitElement {
  static styles = styles;


  constructor() {
    super();   
  }

  render() {
	  const tmpl = this.constructor.template || template
    return tmpl(this);
  }

  attachListeners() {
	
  }

  get context() {
    if (this._context) {
      return this._context;
    }
    const terminal = document.querySelector("harmony-terminal");
    this.context = terminal.context;
    return this._context;
  }

  set context(ctx) {
    this._context = ctx;
  }

  async loadSound(url, options = {}) {
    const sound = await createAudioBuffer(url, options);
    return sound;
  }

  async playSound(name) {

    const sounds = {
      chatMessageShow:"https://westridgein.s3.us-east-1.amazonaws.com/sfx/prompts/print-short.wav",
      chatMessageHide: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/prompts/print-veryshort.wav",

      dialogOpen: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/prompts/print-short.wav",
      dialogLeave: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/prompts/print-veryshort.wav",
      
      message: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/prompts/print-short.wav",
      messageLeave: "https://westridgein.s3.us-east-1.amazonaws.com/sfx/prompts/print-veryshort.wav",
    }

    const url = sounds[name] || `/sfx/prompts/print-veryshort.wav`; // default sound

    const endPromise = new Promise(async (resolve, reject) => {
      const sound = await this.loadSound(url);

      sound.source.onended = (e) => {
        resolve(e)
      }
      sound.source.onerror = (e) => {
        reject(e)
      }

      sound.source.start();
    })

    return endPromise    
    
  }

  qs(selector) {
  	return this.shadowRoot.querySelector(selector);
  }

  qsa(selector) {
  	return this.shadowRoot.querySelectorAll(selector);
  }

  attachedCallback() {
	// override this
  }

  fireEvent(name, options = {}) {

    if (typeof name === "object") { 
      options = name;
      name = options.name;
    }
    const detail = options.detail || {};

    
    this.dispatchEvent(new CustomEvent(name, { detail, bubbles: true, composed: true, cancelable: true }));
  }
  

  connectedCallback() {
    super.connectedCallback();

    this.detachEvents = this.attachListeners();

    setTimeout(() => {
      this.destroy = this.attachedCallback();
    }, 0)
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.detachEvents) {
      this.detachEvents();
    }
    if (this.destroy && typeof this.destroy === "function") {
      this.destroy();
    }	
    
    // override this
  }


}

export { Element, html, css, svg }

customElements.define("harmony-element", Element);
