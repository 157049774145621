export default {
	items: [
		
		{
			type: "Cube", 
			props: {
				position: { x: 3, y: 1, z: 1 }
			}
		},
		{
			type: "Cube", 
			props: {
				position: { x: 3, y: 1, z: 1 }
			}
		},
{
			type: "Paper", 
			props: {
				position: { x: 0, y: 0, z: 0 },
				texture: {
					type: 'image',
					url: '/img5.jpg'
				}
			}
		}
		
	]
}


/*

{
			type: "Sphere", 
			props: {
				position: { x: -8, y: 0, z: 0 }
			}, children: [
				{
					type: "Sphere", 
					props: {
						position: { x: -10, y: 1, z: 1 }
					}
				}
			]
		},

{
			type: "Paper", 
			props: {
				position: { x: 0, y: 0, z: 0 },
				texture: {
					type: 'image',
					url: '/img5.jpg'
				}
			}
		}

{
			type: "Cube", 
			props: {
				position: { x: -1, y: 1, z: 2 }
			}
		}, 
		{
			type: "Cube", 
			props: {
				position: { x: 3, y: 1, z: 4 }
			}
		},
		{
			type: "Sphere", 
			props: {
				position: { x: 1, y: 5, z: 0 }
			}, children: [
				{
					type: "Sphere", 
					props: {
						position: { x: -1, y: 1, z: 1 }
					}
				}
			]
		},
		{
			type: "SpriteParticles", 
			props: {
				position: { x: 1, y: 1, z: 1 }
			}
		},

		*/