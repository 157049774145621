import Node from "./node"
import Gain from "./gain"
import Biquad from "./biquad"
import Equalizer from "./equalizer"
import Analyser from "./analyser"
import Meter from "./meter"
import StereoPanner from "./spanner"
import Visualizer from "./visualizer"
import Beat from "./beat"

const mediaSourcesMap = new WeakMap()
const audioFilters = new Map()

let audioCtx

class WebAudio {
	static register(name, klass) {
		audioFilters.set(name, klass)
	}
	static get(name) {
		return audioFilters.get(name)
	}
	static has(name) {
		return audioFilters.has(name)
	}
	static all() {
		return audioFilters
	}
	static get ctx() {
		if (audioCtx) {
			return audioCtx
		}
		const AudioContext = window.AudioContext || window.webkitAudioContext;
		audioCtx = new AudioContext()
		return audioCtx
	}

	constructor(conf, elem, parent) {
		this.conf = conf
		this.elem = elem
		this.parent = parent

		//console.log("CREATING NODES WITH: ", this.conf.nodes);

		this.nodes = (this.conf.nodes || []).reduce((acc, nc) => {

			

			var Node = WebAudio.get(nc.fname)
			//console.log("nc.fname", nc.fname, Node);

			if (Node) {
				acc.set(nc.id, new Node(nc, elem, this))
				Object.defineProperty(this, nc.id, {
					get() {
						return acc.get(nc.id)
					}
				})
			}
			return acc
		}, new Map() )

		const nodes = Array.from(this.nodes.values())
		this.each = f => nodes.forEach(f)
		this.map = f => nodes.map(f)
	}

	loadFile(file) {
		return new Promise(async resolve => {
			const resp = await fetch(file)
			const respBuffer = await resp.arrayBuffer()


			this.ctx.decodeAudioData(respBuffer, async (buffer) => {
				resolve(buffer)
			})
		})
	}
	
	async init({ file }) {
		const elem = this.elem

		let buffer = null



		if (file) {
			const url = new URL(file)

			buffer = await this.loadFile(url.pathname)
		}
		
		let initialized = false

		const initHandler = (e) => {

			return new Promise((resolve, reject) => {
				if (initialized) { return resolve() }

				this.ctx.resume().then(async () => {
					
					//elem.removeEventListener('play', initHandler)
					await Promise.all(this.map( node => node.init(buffer) ))
					this.connect()
					initialized = true

					resolve()

				}).catch(error => {
					console.log("error", error);
					reject(error)
				})
			})

			
		}
		
		initHandler()
		elem.addEventListener('play', initHandler)

		this.destroyEvents = () => {
			elem.removeEventListener('play', initHandler)
		}
	}

	connect() {
		const nodes = this.conf.nodes || []

		const getLead = (inp) => {

			// console.log("get lead", inp);

			if (inp.id == 'src') {
				return this
			}
			return this.nodes.get(inp.id)
		}

		nodes.forEach(({ id, ins }) => {
			const node = this.nodes.get(id)
			if (node) {
				if (!ins.length) {
					ins = [{ id: 'src', i: 'in' }]
				}
				var inputs = ins.map(getLead)
				node.connect(inputs)
			}
		})
		const dests = this.conf.dests || []
		// console.log("dests", dests);

		if (!dests[0]) {
			throw new Error("Atleast one destination must be provided")
		}

		this.dests = dests.map(({ id }) => {
			const node = this.nodes.get(id)
			if (node) {
				node.output.connect( this.destination )
				return node
			}
		}).filter(Boolean)
	}

	get destination() {
		return this.ctx.destination
	}

	get input() {

	}

	get output() {
		if (!mediaSourcesMap.has(this.elem)) {
			mediaSourcesMap.set(this.elem, this.ctx.createMediaElementSource( this.elem ))
		}
		return mediaSourcesMap.get(this.elem)
	}

	get ctx() {
		return this.constructor.ctx
	}
	get site() {
		return this.parent.entity.site
	}

	destroy() {
		this.each(node => node.disconnect() )
		console.log("this.dests", this.dests);
		
		if (this.dests) {
			this.dests.forEach(node => {
				console.log("dest node", node);
				node.output.disconnect()
			})
		}

		if (this.destroyEvents) {
			this.destroyEvents()
		}
	}

	reset() {
		this.each(node => node.reset && node.reset())
	}

	get props() {
		return this.conf
	}

	get name() {
		return this.conf.name + this.conf.id
	}

	get options() {
		return (this.conf && this.conf.properties) || {}
	}

	tick(time) {
		this.each(n => n.tick && n.tick(time))
	}
}


WebAudio.register('node', Node)
WebAudio.register('gain', Gain)
WebAudio.register('biquad', Biquad)
WebAudio.register('equalizer', Equalizer)
WebAudio.register('analyser', Analyser)
WebAudio.register('meter', Meter)
WebAudio.register('spanner', StereoPanner)
WebAudio.register('visualizer', Visualizer)
WebAudio.register('beat', Beat)

export default WebAudio
