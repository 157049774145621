
export class TextScrambler {
  constructor(el) {
    this.el = el;
    this.chars = "!<>-_\\/+*^?#__";
    this.update = this.update.bind(this);
  }
  setText(newText = '') {
    const oldText = this.el.innerText;
    const length = Math.max(oldText.length, newText.length);
    const promise = new Promise((resolve) => (this.resolve = resolve));
    this.queue = [];
    for (let i = 0; i < length; i++) {
      const from = oldText[i] || "";
      const to = newText[i] || "";
      const start = Math.floor(Math.random() * 20);
      const end = start + Math.floor(Math.random() * 20);
      this.queue.push({ from, to, start, end });
    }
    cancelAnimationFrame(this.frameRequest);
    this.frame = 0;
    this.update();
    return promise;
  }
  update() {
    let output = "";
    let complete = 0;
    for (let i = 0, n = this.queue.length; i < n; i++) {
      let { from, to, start, end, char } = this.queue[i];
      if (this.frame >= end) {
        complete++;
        output += to;
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = this.randomChar();
          this.queue[i].char = char;
        }
        output += `<span class="dud">${char}</span>`;
      } else {
        output += from;
      }
    }
    this.el.innerHTML = output;

    if (complete === this.queue.length) {
      this.resolve();
    } else {
      this.frameRequest = requestAnimationFrame(this.update);
      this.frame++;
    }
  }
  randomChar() {
    return this.chars[Math.floor(Math.random() * this.chars.length)];
  }
}




export default function ScrambleText(el, text, options = {}) {

	const { repeat = 10000, speed = 8 } = options;

  let inView = false;
  let timerid = null;


  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      // Each entry describes an intersection change for one observed
      // target element:
      //   entry.boundingClientRect
      //   entry.intersectionRatio
      //   entry.intersectionRect
      //   entry.isIntersecting
      //   entry.rootBounds
      //   entry.target
      //   entry.time

      if (entry.isIntersecting) {
        inView = true;
        clearTimeout(timerid);
        next()
      } else {
        inView = false;
        clearTimeout(timerid);
       
      }
    });
  };

  const observer = new IntersectionObserver(callback, {
    rootMargin: "0px",
    threshold: 1.0,
  });

  

	const phrases = text || [
      "Neo,",
      "Neo",
      "Neo",
      "Neo",
      "sooner or later",
      "you're going to realize",
      "just as I did",
      "that there's a difference",
      "between knowing the path",
      "and walking the path",
    ];

	const fx = new TextScrambler(el);

	let counter = 0;
  let repeatCounter = 0;

	var next = () => {

    //console.log("phrases[counter]", phrases[counter], repeat, text, inView)

    if (!inView) {
      timerid = setTimeout(next, speed*100);
      return
    }
    
		fx.setText(phrases[counter]).then(() => {
      if (phrases.length === counter + 1) {
        if (repeat && repeatCounter < repeat) {
            timerid = setTimeout(next, speed*100);
            repeatCounter += 1;
          }
          options.onComplete && options.onComplete();
      } else {
        timerid = setTimeout(next, speed*100);
      }
		
		counter = (counter + 1) % phrases.length;
		});
	};

	next();

  observer.observe(el);

	return () => {
		observer.disconnect()
	}

}


