
import { Element, html, css } from "../../element";
import "./section";
import "../stage";


import data from "../data";

const styles = css`
  :host {
    display: block;
    padding: 0;
  }
  .container {
    pointer-events: all;
	
  }
  .stage {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
  }
`;

const template = function (elem) {

	const { data, context } = elem

	const sections = data.items || [];

	if (!context) {
		return html`<div>no context</div>`;
	}

	return html`
		<div class="stage">
			<harmony-stage .data=${data} .context=${context}></harmony-stage>
		</div>
		
		<div class="container"> 		
			<div class="content">
				<div class="content-container">
					${sections.map(section => html`
						<harmony-three-section .content=${section}></harmony-three-section>
					`)}
				</div>
			</div>
		</div>
	`
};


export class Root extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    type: { type: String },
	content: { type: Object, },
	context: { type: Object, attribute: true },
	shape: { type: Object, attribute: false },
  };

  constructor() {
    super();
	this.content = data;
  }

  get data() {
	return this.content || {};
  }

  onClick(e) {
	console.log("clicked", e);
  }

  attachedCallback() {
	console.log("section attached", this.content);
  }


}

customElements.define("harmony-three", Root);
