import chroma from "chroma-js";

export function rand(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}


export function logger(timeFrame) {
    var lastTime = 0;
    return function(...data) {
        var now = new Date();
        if (now - lastTime >= timeFrame) {
            lastTime = now;
        }
    };
}

export function wrap(inValue, inMin, inMax) {
	let valueRange = inMax - inMin;
	return (inMin + ((((inValue - inMin) % valueRange) + valueRange) % valueRange));
}

export function lerp(value1, value2, amount) {
	amount = amount < 0 ? 0 : amount;
	amount = amount > 1 ? 1 : amount;
	return value1 + (value2 - value1) * amount;
}

export function distance(x1, y1, x2, y2) {
    const a = x1 - x2;
    const b = y1 - y2;

    return Math.sqrt(a * a + b * b);
}

export function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

export function random(min, max, float = false) {
    const n = Math.random() * (max - min + 1)
    return (float ? n : Math.floor(n)) + min;
}

export function randomColor(hue1 = 0, hue2 = 360, s = 90, l = 80) {
    return `hsl(${random(hue1, hue2)}, ${s}%, ${l}%)`
}

// points A: [x, y] B: [x, y], frac: 0 - 1
export function interpolate(a, b, frac, arr) {   
    var x = a[0]+(b[0]-a[0])*frac;
    var y = a[1]+(b[1]-a[1])*frac;
    if (arr) {
        arr[0] = x;
        arr[1] = y
        return arr
    } 

    return [x, y]
}

/**
 * Remap
 * maps a number from one scale to another
 * example: n = 0.5; a number between -1 and 1,
 *          to remap n to a number between 20 and 40
 *          num = remap(n, -1, 1, 20, 40)
 * 
 * @param {number} n to remap 
 * @param {number} a current scale lower
 * @param {number} b current scale upper
 * @param {number} c new scale lover
 * @param {number} d new scale upper
 */
export function remap(n, a, b, c, d) {
    return ((n - a) * (d - c) / (b - a)) + c
}


/**
 * clamp
 * @param {number} n number to clamp
 * @param {number} min min value
 * @param {number} max max value
 */
export function clamp(n, min, max) {
    return Math.min(Math.max(n, min), max);
}

/**
 * Rounds a number if necessary
 * @param {*} n 
 */
export function round(n) {
    return Math.round((n + Number.EPSILON) * 100) / 100
}


/**
 * Test if a value is callable
 * @param {*} n 
 */
const FUNCTION = 'function';
export function isCallable(a) {
    return typeof a === FUNCTION
}



export function polarToCartesian(x, y, radius, angle) {
	const rad = (angle-90) * Math.PI / 180.0;
  
	return {
	  x: x + (radius * Math.cos(rad)),
	  y: y + (radius * Math.sin(rad))
	};
}


/**
 * Set
 * Deep set value of object
 * @param {*} obj 
 * @param {*} path 
 * @param {*} value 
 */
export function set(obj, path, value) {
    if (Object(obj) !== obj) {
        return obj; 
    }
    
    if (!Array.isArray(path)) {
        path = path.toString().match(/[^.[\]]+/g) || []; 
    }

    const key = path.pop()

    const target = path.reduce((a, c, i) => 
        Object(a[c]) === a[c]
            ? a[c] 
            : a[c] = Math.abs(path[i+1])>>0 === +path[i+1] 
                   ? []
                   : {}, obj)

    if (isCallable(value)) {
        value = value(target[key])
    }         
    target[key] = value;

    return value;
};



/**
 * Get
 * Deep get value of object
 * @param {*} obj 
 * @param {*} path
 */
export function get(obj, path) {
    if (!Array.isArray(path)) {
        path = path.toString().match(/[^.[\]]+/g) || []; 
    }
    let current = obj

    for (let p of path) {
        if (!p) {
            continue
        }
        if (current[p] === undefined) { 
            return;
        } else {
            current = current[p];
        }
    }
    
    return current;
}






export function throttle(fn, rate = 0) {
    let wait = false;
    let result
    return function() {
        if (!wait) {
            result = fn();
            wait = true
            setTimeout(() => wait = false, rate)
        }
        return result
    }
}



export function debounce(fn, delay = 0) {
    let timer = false;
    return function(...args) {
        clearTimeout(timer)
        timer = setTimeout(() => fn(...args), delay)
    }
}





export function toCSSVars(props = {}) {
    const vars = props.variables || props
    return Object.entries(vars).reduce((str, [key, value]) => {
        return str + `--${key.replace(/^(\-\-)?/, '')}: ${value};`;
    }, '');
}


/**
 * This function takes a canvas, context, width and height. It scales both the
 * canvas and the context in such a way that everything you draw will be as
 * sharp as possible for the device.
 *
 * It doesn't return anything, it just modifies whatever canvas and context you
 * pass in.
 *
 * Adapted from Paul Lewis's code here:
 * http://www.html5rocks.com/en/tutorials/canvas/hidpi/
 */
export function scaleCanvas(canvas, context, width, height, devicePixelRatio = 1) {
    // assume the device pixel ratio is 1 if the browser doesn't specify it
    
    // determine the 'backing store ratio' of the canvas context
    const backingStoreRatio =
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;

    // determine the actual ratio we want to draw at
    const ratio = devicePixelRatio / backingStoreRatio;

    if (devicePixelRatio !== backingStoreRatio) {
        // set the 'real' canvas size to the higher width/height
        canvas.width = width * ratio;
        canvas.height = height * ratio;

        // ...then scale it back down with CSS
        canvas.style.width = width + 'px';
        canvas.style.height = height + 'px';
    } else {
        // this is a normal 1:1 device; just scale it simply
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = '';
        canvas.style.height = '';
    }

    // scale the drawing context so everything will work at the higher ratio
    context.scale(ratio, ratio);

    return ratio;
}




export function createGradient(ctx, { stops = [[0, '#000'], [1, '#fff']], x = 0, y = 0, w = 100, h = 100 } = {}) {
    const g = ctx.createLinearGradient(x, y, w, h);
    for (let stop of stops) {
        g.addColorStop(stop[0], stop[1]);
    }
    return g;
}

export function interpolateColors(colors = ['#000', '#fff'], steps = 10, fade = false) {
    return chroma.bezier(colors).scale().colors(steps).map((c, i) => [i/steps, chroma(c).alpha(fade ? 1-i/steps : 1)])
}

export function bezierGradient(ctx, { colors = ['#000', '#fff'], x = 0, y = 0, w = 100, h = 100, steps = 10, fade = false } = {}) {
    const stops = interpolateColors(colors, steps, fade)
    
    return createGradient(ctx, { x, w, h, y, stops })
}

export function drawBezierCurve(ctx, points, tension) {
        //ctx.moveTo(points[0][0], points[0][1]);
    
        var t = (tension != null) ? tension : 1;
        for (var i = 0; i < points.length - 1; i++) {
            var p0 = (i > 0) ? points[i - 1] : points[0];
            var p1 = points[i];
            var p2 = points[i + 1];
            var p3 = (i != points.length - 2) ? points[i + 2] : p2;
    
            var cp1x = p1.x + (p2.x - p0.x) / 6 * t;
            var cp1y = p1.y + (p2.y - p0.y) / 6 * t;
    
            var cp2x = p2.x - (p3.x - p1.x) / 6 * t;
            var cp2y = p2.y - (p3.y - p1.y) / 6 * t;
    
            ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2.x, p2.y);
        }
}


export function drawQuadraticCurve(ctx, points) {
    //ctx.moveTo((points[0][0]), points[0][1]);
    const len = points.length
    for(let i = 0; i < len-1; i ++) {
        var x_mid = (points[i][0] + points[i+1][0]) / 2;
        var y_mid = (points[i][1] + points[i+1][1]) / 2;
        var cp_x1 = (x_mid + points[i][0]) / 2;
        var cp_x2 = (x_mid + points[i+1][0]) / 2;
        ctx.quadraticCurveTo(cp_x1,points[i][1] ,x_mid, y_mid);
        ctx.quadraticCurveTo(cp_x2,points[i+1][1] ,points[i+1][0],points[i+1][1]);
    }
}


export function drawPolyLine(ctx, points) {
    ctx.moveTo((points[0][0]), points[0][1]);
    const len = points.length
    for(let i = 0; i < len-1; i ++) {
        ctx.lineTo(points[i][0], points[i][1])
    }
}


export function zoomable(_scroll, dims, opts = {}) {
    const minzoom = opts.min ?? 0.1;
    const maxzoom = opts.max ?? 8;
    const speed = opts.speed ?? 0.05;
    // const minx = opts.x;
    // const miny = opts.y;

    const horizontal = opts.dir == 'x';
    const vertical = opts.dir == 'y';

    const setX = (x) => {
        if (vertical) { return }
        _scroll.x = x;
    }
    const setY = (y) => {
        if (horizontal) { return }
        _scroll.y = y
    }
    const setZ = (z) => {
        _scroll.zoom = z;
    }

    const zoom = (x, y, dy) => {

        const w = dims.width;
        const h = dims.height;

        const sx = _scroll.x,
            sy = _scroll.y,
            oz = _scroll.zoom,
            ow = w * oz,
            oh = h * oz;

        const ox = x - sx,
            oy = y - sy;

        const nz = clamp(dy > 0 ? oz - speed : oz + speed, minzoom, maxzoom);


        const nw = (nz * ow) / oz,
            nh = (nz * oh) / oz,
            k = nw / ow,
            nx = x - k * ox,
            ny = y - k * oy;


        setZ(nz);
        setX(nx);
        setY(ny);

    };

    return zoom;
}



export class RAF {
    constructor(func, fps = 32, scope = {}) {
        var lastFrameTime = 0;

        const exec = (elapsedTime) => {
            const delta = elapsedTime - lastFrameTime;

            this.rafId = requestAnimationFrame(exec);

            if (lastFrameTime && delta < fps) {
                return;
            }

            lastFrameTime = elapsedTime;
            func(elapsedTime);
        };

        this.rafId = requestAnimationFrame(exec);
    }

    cancel() {
        return cancelAnimationFrame(this.rafId);
    }
}

export function Wheel(elem, handler, options = {}) {
    const wheel = function(e) {
        let delta = 0,
            deltaX = 0,
            deltaY = 0;
        let div = 3;

        if (e.wheelDelta) {
            delta = e.wheelDelta / div;
        }
        if (e.wheelDeltaX) {
            deltaX = e.wheelDeltaX / div;
        }
        if (e.wheelDeltaY) {
            deltaY = e.wheelDeltaY / div;
        } else if (e.detail) {
            delta = -e.detail / div;
        }

        if (options.preventDefault !== false) e.preventDefault();

        handler(e, delta, deltaX, deltaY);
    };

    elem.addEventListener('DOMMouseScroll', wheel, false);
    elem.addEventListener('mousewheel', wheel, false);

    return {
        unbind() {
            elem.removeEventListener('DOMMouseScroll', wheel, false);
            elem.removeEventListener('mousewheel', wheel, false);
        }
    };
}


