import Node from "./node"

export function lerp(value1, value2, amount) {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;
  return value1 + (value2 - value1) * amount;
}

class Beat extends Node {

	init(buffer) {

		const ctx = this.ctx
		
		var gain = this.ctx.createGain()
		gain.gain.value = this.config.gain || 1

		this.node = gain

		this.velocity = 0
		this.data = []

		this.reset = () => {
			this.lastBeat = 0
		}

		return this.analyze(buffer)
	}

	async analyze(buffer) {

		const { 
				filterFrequency = 100, 
				peakGain = 15, 
				threshold = 0.8, 
				minAnimationTime = 0.4, 
				sampleSkip  = 350
		} = this.config


		const offlineContext = new OfflineAudioContext(
			1,
			buffer.length,
			buffer.sampleRate
		  );
		  const source = offlineContext.createBufferSource();
		  source.buffer = buffer;
	  
		  const filter = offlineContext.createBiquadFilter();
		  filter.type = "bandpass";
		  filter.frequency.value = filterFrequency;
		  filter.Q.value = 1;
	  
		  const filter2 = offlineContext.createBiquadFilter();
		  filter2.type = "peaking";
		  filter2.frequency.value = filterFrequency;
		  filter2.Q.value = 1;
		  filter2.gain.value = peakGain;
	  
		  source.connect(filter2);
		  filter2.connect(filter);
		  filter.connect(offlineContext.destination);
		  source.start();

		  const dbuffer = await offlineContext.startRendering();
	  
		  const data = dbuffer.getChannelData(0);
	  
		  const songData = [];

		  for (let i = 0; i < data.length; ++i) {
			if (data[i] > threshold) {
			  const time = i / dbuffer.sampleRate;
			  const previousTime = songData.length
				? songData[songData.length - 1].time
				: 0;
			  if (time - previousTime > minAnimationTime) {
				songData.push({
				  data: data[i],
				  time,
				});
			  }
			}
			i += sampleSkip;
		  }

		  

		  this.songData = songData

	}


	subscribe(fn) {
		if (!this.callbacks) {
			this.callbacks = []
		}
		this.callbacks.push(fn)
	}
	
	unsubscribe(fn) {
		if (!this.callbacks) {
			return
		}

		const idx = this.callbacks.indexOf(fn)
		this.callbacks.splice(idx, 1)
	}


	addBeat(value) {
		const pulse = 0.08

		this.didBeat = 0

		if (value) {
			this.velocity = 1
			this.didBeat = 1
		} 
		if (this.didBeat) {
			this.didBeat -= 0.25
		} else {
			this.velocity = lerp(this.velocity, value, pulse)
		}	
		

		this.data.unshift(this.velocity)
		if (this.data.length > 100) {
			this.data.pop()
		}
	}



	tick(time) {
		if (!this.songData) {
			return
		}

		const dur = this.elem.duration
		const curTime = this.elem.currentTime

		const { lastBeat = 0 } = this


		for (let i=lastBeat,l=this.songData.length; i<=l; i++) {
			const beat = this.songData[i]
			const nextBeat = this.songData[i+1]

			if (beat && nextBeat) {
				if (curTime >= beat.time && curTime <= nextBeat.time) {
					this.lastBeat = i+1;
					this.callbacks && this.callbacks.forEach(fn => fn(beat, i, curTime))
					this.addBeat(1)	
							
				} else {
					this.addBeat(0)	
				}
				break;
			} else if (!nextBeat) {
				this.lastBeat = 0;
				break;
			}
			//break;
			// console.log(i, i+1, nextBeat)
		}
		
	}

}

export default Beat
