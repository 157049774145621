import Node from "./node"



class Biquad extends Node {


	init() {
		const ctx = this.ctx,
			node = ctx.createBiquadFilter()

		const apply = this.reset = () => {
			const { type, gain = 1, q = 1, frequency = 2.5 } = this.config

			node.type = type
	        node.frequency.value = Node.getFreq(frequency, ctx.sampleRate)
	        node.gain.value = gain
	        node.Q.value = Node.getQ(q/2) * 1000 //q <= 1 ? qt(q) / 100 : qt(q - 1) * 100
		}
		this.node = node
		apply()
	}


}

export default Biquad
