import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

import gsap from "gsap";

const styles = css`
  :host {
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .container {
    width: 100vw;
    height: 100vh;
		pointer-events: all;
  }
  .hidden {
	display: none;
  }
  button {
    font-size: 1.5rem;
    background-color: var(--color-eternity, #000);
    pointer-events: all;
  }
  button:hover {
    background-color: red;
  }
  .bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-white, #000);
	z-index: 1;
	transform-origin: top;
  }
  .content {
	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }
  .icon {
	width: 100px;
	height: 100px;
	margin: 40px 0;
  }
  .icon svg {
	fill: white;
	stroke:  var(--color-eternity-200);
  }
`;

const template = function (elem) {
	if (elem.disabled) {
		return ``
	}
  return html`
    <div class="container "> 
		<div class="content">
			<div class="quote">
				Cruenty created empathy. Kindness created harmony.
			</div>
			
			<h1>${elem.greeting || "undefined"}</h1>

			<div>Headphones recommended</div>

			<div class="icon">
				<?xml version="1.0" encoding="UTF-8"?>
					<svg viewBox="0 0 101 101">
					<g>
						<path class="cls-2" d="M80.08,52.87c.9.25,1.71.74,2.32,1.44,1.07,1.19,1.47,2.85,1.05,4.4l-6.69,24.93h0c-.33,1.23-.16,2.52.48,3.62s1.68,1.9,2.9,2.23,2.52.16,3.61-.48c1.09-.63,1.9-1.68,2.23-2.9l11.1-41.43c1.68-6.11.85-12.63-2.32-18.11-3.16-5.49-8.4-9.47-14.53-11.07-12.07-3.24-16.93,7.68-18.75,14.49-1.5,5.6-3.64,18.99,8.88,22.34,2.53.68,5.17.84,7.77.45.65-.1,1.31-.07,1.94.1h0Z"/>
						<path class="cls-2" d="M64.29,36.22c0-6.59,3.21-11.94,7.17-11.94s7.16,5.35,7.16,11.94-3.21,11.93-7.16,11.93-7.17-5.34-7.17-11.93"/>
						<path class="cls-2" d="M20.94,14.62c-6.13,1.6-11.35,5.58-14.52,11.07-3.17,5.49-4.01,12.01-2.32,18.11l11.11,41.43c.33,1.22,1.12,2.26,2.22,2.9s2.4.8,3.62.48c1.22-.33,2.26-1.12,2.9-2.22.63-1.09.8-2.4.47-3.62l-6.69-24.93h0c-.42-1.56-.02-3.21,1.05-4.41,1.06-1.2,2.67-1.78,4.26-1.54,2.6.39,5.24.24,7.78-.45,12.52-3.36,10.38-16.74,8.88-22.34-1.84-6.79-6.69-17.71-18.76-14.48h0Z"/>
					</g>
				</svg>
			</div>
      		
      		<div>
				<harmony-button @click=${(e) => elem.initialize(e)} variant="outline">
					Enter with sound
				</harmony-button>
				
			</div>
		</div>
      
	  <div class="bg"></div>
    </div>
  `;
};


export class Intro extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    greeting: { type: String },
    data: { type: Object },
  };

  constructor() {
    super();
    this.greeting = "Hello";
    this.data = { name: "Evee" };

	this.disabled = false;
  }

  async initialize(e) {


	const main = document.querySelector("body > main");
	const container = this.shadowRoot.querySelector(".container");

	
	const bg = this.qs(".bg");


	main.classList.remove("inactive");
	
	this.sendInitEvent();


	gsap.to(bg, { 
		scaleY: 0, 
		opacity: 1, 
		onComplete: () => {
			//bg.style.display = "none";
			
			container.classList.add("hidden");
		}
	})
    
  }

  sendInitEvent() {
	const event = new CustomEvent("initialize", {
	  detail: {
		message: "initialize",
	  },
	  bubbles: true,
	  composed: true,
	});
	this.dispatchEvent(event);
  }


  attachedCallback() {

	const h1 = this.qs(".quote");

	if (this.disabled) {
		return
	}

	const destroy = ScrambleText(h1, ["Cruelty created empaty", "Kindness creates Harmony"]);


	return () => {
		destroy();
	}
	


  }





}

customElements.define("harmony-intro", Intro);
