import { Element, html, css } from "../element";


const styles = css`
  :host {
    display: inline-block;
  }
`;

const template = function (elem) {
	const { name = "harmony", props = {}} = elem;
  return html`
    <div>${name}</div>
  `;
};


export class Icon extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    name: { type: String },
  };

  constructor() {
    super();   
  }



}

customElements.define("harmony-icon", Icon);
