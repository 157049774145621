import { Element, html, css } from "../element";


const styles = css`
  :host {
    display: inline-block;
  }
  
  button {
	background: var(--harmony-color-white-800);
	color: var(--button-color, var(--harmony-color-blue-800));
	border: none;
	padding: 10px 40px;
	font-size: var(--button-font-size, 1.4rem);
	cursor: pointer;
	border-radius: 100px;
	font-family: var(--font-lg);
	width: fit-content;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	text-transform: uppercase;
	letter-spacing: 1px;
}

	.outline {
		background: transparent;
		color: var(--button-color, var(--harmony-color-blue-800));
		border: 2px solid var(--button-color, var(--harmony-color-blue-800));
	}

	.blue {
		background: var(--color-ice);
		color: var(--button-color, var(--color-night));
		box-shadow: var(--color-ice) 0 10px 20px -10px;
	}
	.red {
		background: var(--color-fire);
		box-shadow: var(--color-fire) 0 10px 20px -10px;
	}

	.blue:hover {
		transform: translate(0, -5px);
		box-shadow: var(--color-ice) 0 20px 20px -10px;
	}
	.red:hover {
		transform: translate(0, -5px);
		box-shadow: var(--color-fire) 0 20px 20px -10px;
	}

`;

const template = function (elem) {
	const { type = "button", variant = "white" } = elem;
  return html`
    <button class="${variant}" type="${type}" @click=${(e) => elem.onClick(e)}><slot></button>
  `;
};


export class Button extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    type: { type: String },
	variant: { type: String },
  };

  constructor() {
    super();   
  }

  onClick(e) {
	
  }


}

customElements.define("harmony-button", Button);
