
import Node from "./node"

class Meter extends Node {

	constructor(...args) {
		super(...args)

		const { length = 50 } = this.config
		const noChannels = 2
		const data = { volume: 0, channels: [] }

		for (let i = 0; i < noChannels; i++) {
            const c = {
				volume: 0,
				peaks: (new Array(length)).fill(0),
				peaksd: (new Array(length)).fill(0),
				data: (new Array(length)).fill(0)
			};
            data.channels.push(c)
        }

		this.define({
			data: () => data,
			//peaks: () => peaks
		})

	}

	init() {
		const ctx = this.ctx

		const analysers = []
        const dx = []
		const node = this.ctx.createGain()

        if (this.props.off) {
            // passthrough
            return;
        }

        for (let i = 0; i < 2; i++) {
            const analyser = ctx.createAnalyser()
            analyser.smoothingTimeConstant = 0.3
            analyser.fftSize = 1024
            analysers.push(analyser)
        }

        const splitter = ctx.createChannelSplitter()

        splitter.connect(analysers[0],0,0)
        splitter.connect(analysers[1],1,0)

        node.connect(splitter)


		const data = this.data

		const tick = (time) => {
			const len = this.config.length || 50
	        const fall = (this.config.fall || 80) / 100
			const height = 100

	        let mvol = 0


	        for (let idx = 0; idx < 2; idx++) {

	            const analyser = analysers[idx]

	            if (!analyser.array) {
					analyser.array = new Uint8Array(analyser.frequencyBinCount)
				}

	            const { array } = analyser
	            analyser.getByteFrequencyData(array)

	            const { length } = array
	            const bin_size = Math.floor(length / len)

	            const sum = array.reduce(((s, v) => (s + v)), 0)

	            if (!dx[idx]) { dx[idx] = 0 }

	            const a = sum  / length
	            dx[idx] += ( (a || 0) - dx[idx] ) * fall
	            mvol += a

	            const dc = data.channels[idx]

	            dc.peaks.length = dc.peaksd.length = dc.data.length = len

	            dc.volume = dx[idx]
	            dc.peaks.push(a)
	            dc.peaksd.push(dc.volume)
	            dc.peaks.shift()
	            dc.peaksd.shift()

				for (let i = 0; i < len; i++) {
					const avg = array[(i * bin_size)]
	                const average = (avg / 256) * height

	                dc.data[i] = average
				}

	        }
	        data.volume = mvol / 2
		}

		this.destroy = () => {
			splitter.disconnect()
		}

		this.node = node // gain node
		this._tick = tick
	}



	tick(time) {
		this._tick && this._tick(time)
	}


	connect(sources) {
		this.source = sources[0]
		this.source.output.connect(this.input)
	}


}

export default Meter
