import { Element, html, css } from "../element";


const styles = css`
  :host {
    display: inline-block;
  }
`;

const template = function (elem) {
	const { type = "button", variant = "white" } = elem;
  return html`
    <slot></slot>
  `;
};


export class AttractiveButton extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    type: { type: String },
	variant: { type: String },
  };

  constructor() {
    super();   
  }

  onClick(e) {
    
  }


}

customElements.define("harmony-attractive-button", AttractiveButton);
