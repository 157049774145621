import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function ScrollAnimation(elem,  { debug = false, onUpdate, onToggle, start, end } = {}) { 

	//const container = elem.qs(".container");


	gsap.registerPlugin(ScrollTrigger);

    //const setY = gsap.quickSetter(caption.current, "x", "px");
   
    // "bottom top"

    console.log("scroll animation", start, end);

    const options = {
      id: "anim",
      trigger: elem,
      scrub: true,
      start: start ?? "top bottom",
      end: end ?? "bottom top",
      ease: "power3.inOut",
      pin: false,
      onUpdate: (e) => {
        
        if (debug) {
			console.log("entered", e.progress, e.progress);
          //console.log(e.progress, e.progress * windowHeight);
        }

		onUpdate && onUpdate(e)
      },
      onToggle: (self) => {
        if (debug) {
          console.log("toggled", self, self.isActive, self.direction);
        }
		onToggle && onToggle(self)
      },
      onLeave: (self) => {
        //wtl.progress(0);
        //console.log("left", self, self.isActive, self.direction);
      },
      onLeaveBack: (self) => {
        //wtl.progress(0);
        //console.log("leftback", self, self.isActive, self.direction);
      },
    };
    if (debug) {
      options.markers = {
        startColor: "white",
        endColor: "white",
        fontSize: "18px",
        fontWeight: "bold",
        indent: 20,
      };
    }

    const tl = gsap.timeline({
      scrollTrigger: options,
      duration: 1,
    });

	return tl;
}