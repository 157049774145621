const cached = {};

export default async function createAudioBuffer(url, options = {}) {
  const audioCtx = new AudioContext();

  // Create an empty three-second stereo buffer at the sample rate of the AudioContext
  
  let audioBuffer = cached[url];

  if (!audioBuffer) {
    const audioFile = await fetch(url);
    const buffer = await audioFile.arrayBuffer();
    audioBuffer = await audioCtx.decodeAudioData(buffer);
    cached[url] = audioBuffer;
  }


  // Get an AudioBufferSourceNode.
  // This is the AudioNode to use when we want to play an AudioBuffer
  const source = audioCtx.createBufferSource();
  

  // set the buffer in the AudioBufferSourceNode
  source.buffer = audioBuffer;
  

  // connect the AudioBufferSourceNode to the
  // destination so we can hear the sound

  const gainNode = new GainNode(audioCtx);
  gainNode.gain.value = 1;

  source.connect(gainNode)

  const endGainNode = new GainNode(audioCtx);

  gainNode.connect(endGainNode);

  
  endGainNode.gain.value = 0.7;

  
  endGainNode.connect(audioCtx.destination);
  //source.connect(audioCtx.destination);

  source.loop = options.loop || false;

  return {
    audioCtx,
    source,
  };
}
