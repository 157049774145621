//const Wheel = require('../../utils/wheel')

function isRightClick(e) {
  return (e.ctrlKey || e.which == 3) && e.type !== 'contextmenu'
}

function getEvent(el, options) {
  const element = options.proxy || el

  const doc = element.ownerDocument
  const body = doc.body
  const _evt = {
    start: { x: 0, y: 0, angle: 0 },
    delta: { x: 0, y: 0, angle: 0 },
    end: { x: 0, y: 0, angle: 0 }
  }

  let bounds

  function getAngle(evt) {
    const xradius = bounds.width / 2,
      yradius = bounds.height / 2

    let dy = yradius - evt.layer.y
    let dx = xradius - evt.layer.x
    let t = Math.atan2(-dy, -dx)
    //t *= 180 / Math.PI
    //if (t < 0) t += 360
    return t
  }

  const prepareEvent = function (e, evt, start) {
    if (!bounds) bounds = element.getBoundingClientRect()

    if (e.type.indexOf('touch') === 0) {
      evt.x = e.changedTouches[0].pageX
      evt.y = e.changedTouches[0].pageY
    } else {
      evt.x = e.clientX
      evt.y = e.clientY
    }

    evt.layer = evt.layer || {}
    evt.layer.x = (evt.x || 0) - bounds.left
    evt.layer.y = (evt.y || 0) - bounds.top

    const rad = getAngle(evt)

    //console.log("deg", rad);

    //radius = bounds.width / 2;
    //ang = Math.atan2(evt.layer.x - radius, evt.layer.y - radius);
    //deg = -ang / (Math.PI / 180) + 180;
    evt.layer.angle = rad
    evt.angle = rad

    evt.layer.percent = {
      x: (evt.layer.x / bounds.width) * 100,
      y: (evt.layer.y / bounds.height) * 100
    }
    evt.percent = {
      x: (evt.x / window.innerWidth) * 100,
      y: (evt.y / window.innerHeight) * 100
    }
  }

  const cleanupEvent = () => {
    bounds = null
  }

  return { evt: _evt, prepareEvent, cleanupEvent }
}

function Drag(el, options) {
  const element = options.proxy || el

  const doc = element.ownerDocument
  const body = doc.body

  var bounds
  var dragging = false

  const { evt, prepareEvent, cleanupEvent } = getEvent(el, options)
  const _evt = evt

  var startDrag

  const destroy = function (e) {
    //el.releasePointerCapture(e.pointerId) // this causes currentTarget fail
    prepareEvent(e, _evt.end)

    body.classList.remove('draggable-dragging')

    doc.removeEventListener('pointermove', move, { capture: true })
    doc.removeEventListener('pointerup', destroy, { capture: true })

    if (dragging && options.end) options.end(e, _evt.end)

    if (!dragging && options.tap) options.tap(e, _evt.end)

    cleanupEvent()

    bounds = dragging = false
  }

  const init = function (e) {
    //console.log("draggable init", e);
    if (isRightClick(e)) {
      return
    }

    // this causes problems with other click events
    //el.setPointerCapture(e.pointerId)
    //
    startDrag = function () {
      dragging = true
      //el.setPointerCapture(e.pointerId) // this causes currentTarget fail
      prepareEvent(e, _evt.start)
      body.classList.add('draggable-dragging')

      if (options.start) options.start(e, _evt.start)
    }

    doc.addEventListener('pointermove', move, { capture: true })
    doc.addEventListener('pointerup', destroy, { capture: true })
  }

  const move = function (e) {
    if (!dragging) {
      startDrag()
    }

    prepareEvent(e, _evt, _evt.start)
    e.drag = _evt

    let dx = _evt.x - _evt.start.x,
      dy = _evt.y - _evt.start.y,
      xdiff = _evt.delta.x - dx,
      ydiff = _evt.delta.y - dy

    _evt.delta.xdiff = xdiff
    _evt.delta.ydiff = ydiff

    _evt.delta.x = dx
    _evt.delta.y = dy

    const angDelta = _evt.start.angle - _evt.angle

    _evt.delta.angle = angDelta

    if (options.move) options.move(e, _evt)
  }

  el.addEventListener('pointerdown', init, { capture: false })

  return {
    destroy: function () {
      el.removeEventListener('pointerdown', init, { capture: false })
    }
  }
}

export function Interact(elem, actions, options = {}) {
  let wheel = null
  let drag = null
  const handlers = new WeakMap()

  const _on = function (ev, handler) {
    //console.log("handler", ev, handler);
    if (!handler) {
      return
    }

    const h = (e) => {
      // if rightclick, and actions has a rightclick event,
      // prevent this event from firing
      // the contextmenu event will fire
      if (isRightClick(e) && actions.rightclick) {
        return
      }

      const { evt, prepareEvent } = getEvent(elem, options)
      prepareEvent(e, evt)

      handler(e, evt)
    }
    handlers.set(handler, h)

    ev.split(',').forEach(function (evn) {
      elem.addEventListener(evn, h, { capture: false, bubbles: true })
    })
  }

  const _off = function (ev, handler) {
    if (!handler) {
      return
    }
    const h = handlers.get(handler)
    ev.split(',').forEach(function (evn) {
      elem.removeEventListener(evn, h, { capture: false, bubbles: true })
    })
  }

  const add = {
    scroll(handler) {
      wheel = Wheel(elem, handler, options.scroll)
    },
    drag(handler) {
      drag = Drag(
        elem,
        Object.assign({}, options, {
          move: handler,
          start: actions.dragstart,
          end: actions.dragend,
          tap: actions.tap
        })
      )
    },
    move(handler) {
      _on('pointermove', handler)
    },
    enter(handler) {
      _on('pointerenter', handler)
    },
    over(handler) {
      _on('pointerover', handler)
    },
    out(handler) {
      _on('pointerout', handler)
    },
    leave(handler) {
      _on('pointerleave', handler)
    },
    down(handler) {
      _on('pointerdown', handler)
    },
    pointerdown(handler) {
      _on('pointerdown', handler)
    },
    up(handler) {
      _on('pointerup', handler)
    },
    click(handler) {
      _on('click', handler)
    },
    rightclick(handler) {
      _on('contextmenu', handler)
    },
    focus(handler) {
      _on('focus', handler)
    },
    blur(handler) {
      _on('blur', handler)
    },
    dblclick(handler) {
      _on('dblclick', handler)
    },
    dragstart() {},
    dragend() {}
  }

  const remove = {
    scroll(handler) {
      if (wheel) {
        wheel.unbind()
      }
    },
    drag(handler) {
      if (drag) {
        drag.destroy()
      }
    },
    move(handler) {
      _off('pointermove', handler)
    },
    enter(handler) {
      _off('pointerenter', handler)
    },
    leave(handler) {
      _off('pointerleave', handler)
    },
    over(handler) {
      _off('pointerover', handler)
    },
    out(handler) {
      _off('pointerout', handler)
    },
    down(handler) {
      _off('pointerdown', handler)
    },
    pointerdown(handler) {
      _off('pointerdown', handler)
    },
    up(handler) {
      _off('pointerup', handler)
    },
    click(handler) {
      _off('click', handler)
    },
    rightclick(handler) {
      _off('contextmenu', handler)
    },
    focus(handler) {
      _off('focus', handler)
    },
    blur(handler) {
      _off('blur', handler)
    },
    dblclick(handler) {
      _off('dblclick', handler)
    },
    dragstart(handler) {},
    dragend(handler) {}
  }

  /*
	const getHammer = () => {
	   if (hammer) { return hammer }
	   hammer = new Hammer(elem, { threshold: 0});
	   hammer.get('pan').set({direction: Hammer.DIRECTION_ALL, threshold: 0})
	   return hammer
	}
	*/

  for (let action of Object.keys(actions)) {
    let handler = actions[action]
    if (action in add) {
      add[action](handler)
    } else {
      //getHammer().on(action, handler)
    }
  }

  const destroy = function () {
    for (let action of Object.keys(actions)) {
      let handler = actions[action]
      if (action in remove) {
        remove[action](handler)
      } else {
        //getHammer().off(action, handler)
      }
    }
  }

  return {
    destroy: destroy
  }
}
