import { Element, html, css, svg } from "../element";


import {RAF, rand, wrap, clamp, remap } from "../../../utils/ui-utils";
import { simplex3, seed } from "../../../utils/noise";
import ScrollAnimation from "../../../utils/ScrollTimeline";
import { Fireworks } from 'fireworks-js'
import chroma from "chroma-js";

import "./festival.js"
import "../common/text-intro.js"

const styles = css`
  :host {
    display: block;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 200vh;
	
  }
  .container {
    width: 100vw;
	transform-style: preserve-3d;
	perspective: 600px;
  }
  .content {
	width: 100vw;
	min-height: 100vh;
	transform-style: preserve-3d;
  }

  .biodiversity {
	color: #fff;
	width: 100vw;
	height: 200vh;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  .biodiversity .inner {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5vh 10vw;
	position: sticky;
	top: 0;
	text-align: center;
	font-family: var(--headline-font);
	max-width: 800px;
  }
  .biodiversity h4 {
	margin: 0;
	font-size: var(--size-large)
  }
  .biodiversity p {
	margin: 0;
	padding: 0;
	font-size: 1.7rem;
	max-width: 700px;
  }

  .providers {
	width: 100vw;
	height: 400vh;
	color: #fff;
  }
  .provider {
	width: 100vw;
	height: 100vh;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
  }

  .provider .title {
	font-size: 2rem;
	font-family: var(--headline-font);
	text-align: center;
  }
  .provider .magic-provider .logo { 
	opacity: 1;	
  }

  .title.date {
	font-size: 4rem;
	letter-spacing: 0.5rem;
  }
  
`;


const items = [
	{ 
		name: 'restoration', 
		title: ["Planet", "Restoration", "Day"],
		description: `
			We are restoring biodiversity by planting 10,000 trees in a day every week starting now,
			and we invite you to join us in this effort. 
		`,
		media:[ {
			src: "https://westridgein.s3.us-east-1.amazonaws.com/media/restoration.mp4",
			type: "video"
		}],
		
		stats: [
			{ title: "10,000+", description: "Trees" },
			{ title: "2000+", description: "Children" },
			{ title: "1", description: "Day" }
		],
		events: [{
			title: "Commencing Initialization Sequence",
			description: "The importance of restoring forests. Commencement by Sri P. M. Mathew",
			
			media: [{
				caption: "Sri P. M. Mathew",
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/planting/3.webp",
				type: "image"
			}],
			time: ["11 AM", "11:15 AM"],
		}, {
			title: "Attappadi Greening",
			description: "Thousands of children planting tens of thousands of trees.",
			media:[ {
				caption: "Planting 10,000 trees in a day",
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/planting/1.webp",
				type: "image"
			}],
			time: ["11 AM", "4 PM"],
		}, {
			title: "Harmony to Mother Nature",
			description: "Live music, food, dance and a little touch of magic",
			media: [{
				caption: "",
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/planting/2.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}
		]
	},
	{ 
		name: 'diwali', 
		title: ["Diwali", "Festival", "of Lights"],
		description: `Diwali, known as the Festival of Lights, is one of the most celebrated festivals in India and across the world. 
			It marks the triumph of light over darkness, knowledge over ignorance, and good over evil.`,		
		events: [{
			title: "Lamp Lighting",
			description: "Children lighting lamps to symbolize the triumph of light over darkness",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/diwali/1.png",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}, {
			title: "Fireworks",
			description: "Fireworks to celebrate the victory of light, spreading joy and excitement",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/diwali/2.png",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}, {
			title: "Live Music",
			description: "Music, dance and food stalls to celebrate unity and harmony with a little touch of magic",
			media: [{
				caption: "",
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/diwali/3.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}]
	} ,
	{ 
		name: 'kali', 
		title: ["Kaliday", "A New Day for", "Kindness"],
		description: `Kali represents liberation and the destruction of negativity. 
			She is also a symbol of Mother Nature because she is believed to be timeless and formless, representing 
			the creation of life and the universe as well.`,
		media: [{
			src: "https://westridgein.s3.us-east-1.amazonaws.com/media/goddess-kali.png",
			type: "video"
		}],
		
		events: [{
			title: "Red Everywhere",
			description: "Wear a red hibiscus flower for Unity and show your love for Mother Nature",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/kali/4.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}, {
			title: "Kindness Everywhere",
			description: "One random act of kindness for Unity and Mother Earth",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/kali/2.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		},  {
			title: "Love Everywhere",
			description: "Hug a tree. Give a little love to the planet",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/kali/3.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}]
	},
	{ 
		name: 'halloween', 
		title: ["Halloween", "Trick", "or Treat"],
		description: `Halloween has its roots in the ancient Celtic festival of Samhain, a 
			celebration to welcome the harvest at the end of summer, 
			when people would light bonfires and wear costumes to scare off ghosts`,
		
		events: [{
			title: "Trick or Treat",
			description: "Children go door to door asking for candy",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/halloween/1.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}, 
		{
			title: "Costume Contest",
			description: "Children dress up in costumes and compete",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/halloween/2.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		},
		{
			title: "Magic & Sound",
			description: "Magic show by the famous magician with live music",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/halloween/3.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}, ]
	},
	{ 
		name: 'days', 
		title: ["Celebrating", "Harmony", "In Unity"],
		
		events: [{
			title: "Girl Scout Founder's Day",
			description: "Cookies and food stalls by girls celebrating the founder of the Girl Scouts",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/days/1.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}, 
		{
			title: "International Magic Day",
			description: "Live music & magic show celebrating the art of magic",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/days/2.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		},
		{
			title: "National Unity Day",
			description: "Live music celebrating the unity of nature and humanity",
			media: [{
				src: "https://westridgein.s3.us-east-1.amazonaws.com/events/days/5.webp",
				type: "image"
			}],
			time: ["6 PM", "11 PM"],
		}, ]
	} 
]




const template = function (elem) {
	return html`
		
		<div class="container">
			

			
			<div class="biodiversity">
				<div class="inner">	
					<h4>
						<harmony-text-intro .text=${["Restoring", "biodiversity", " in", "farmlands"]}></harmony-text-intro>
					</h4>
					<p>
						We believe that the world is a better place when we all work together to restore the balance of nature.
					</p>
				</div>
			</div>
		

			<div class="content">
				${
					items.map((item, i) => {
						return html`
							<harmony-festival class="festival" .data=${item} @toggle=${elem.onToggle}></harmony-festival>
						`
					})
				}
			</div>

			
			<div class="providers">
				<div class="provider tree-provider">
					<div class="entity tree-provider">
						<div class="title">
							Trees Provided By
						</div>
						<div class="image">
							<div class="logo">
								<img src="/assets/isha-logo.png" />
							</div>
						</div>
					</div>
				</div>
				<div class="provider magic-provider">
					<div class="entity magic-provider">
						<div class="title">
							Magic Performance By
						</div>
						<div class="image">
							<div class="logo">
								<img src="/assets/magician.png" />
							</div>
						</div>
					</div>
				</div>
				<div class="provider magic-provider">
					<div class="guest live-music-provider">
						<div class="title">
							Live Music By
						</div>
						<div class="image">
							<div class="logo">
								<div class="logo">
									<img src="/assets/mehfil-logo.png" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="provider date-provider">
					<div class="guest live-music-provider">
						<div class="title date">
							OCT 31, 2024 <br />
							11 AM - 11 PM
						</div>
						
					</div>
				</div>
				
			</div>


    	</div>
	`
};


export class Restoration extends Element {
  static template = template;
  static styles = styles;

  static properties = {
	
  };

  constructor() {
    super();   
  }


  onToggle(e) {
	

  }

  attachedCallback() {


	const main = this.qs(".container");
	const sections = this.qs(".sections");

	const content = this.qs(".content");
	const titles = this.qs(".titles");

	const video = this.qs(".video");

	const festivals = this.qsa(".festival");


	

	return

	const tl = ScrollAnimation(this, { 
		debug: false, 
		start: "top top",
		end: "bottom bottom",
		onUpdate: (e) => {
			//console.log("depth", e.progress)
		}
	});

	festivals.forEach((festival, i) => {

		tl.fromTo(festival, { translateY: "100%" }, 
			{ translateY: "0%", ease: "power1.inOut", duration: 1 }, i);

		tl.fromTo(festival, { x: 0 }, 
			{ x: 0, ease: "power1.inOut", duration: 1 }, i + 0.5);

		tl.fromTo(festival, { scale: 1, translateY: "0%" }, 
			{ scale: 0.5,  translateY: "-100%", ease: "power1.inOut", duration: 1 }, i + 1);
		
	})

	


  }

}

customElements.define("harmony-restoration", Restoration);



/*

<div class="festival restoration-day">

					<div class="titles">
						<h1><span>World</span><span>Restoration</span><span>Day</span></h1>
					</div>
					
					<div class="video">
						<video src="https://westridgein.s3.us-east-1.amazonaws.com/media/restoration.mp4" autoplay loop muted playsinline></video>						
					</div>

					<div class="stats">
							<div class="stat">
								<div class="title">10,000+</div>
								<div class="description">Trees</div>
							</div>
							<div class="stat">
								<div class="title">2000+</div>
								<div class="description">Children</div>
							</div>
							<div class="stat">
								<div class="title">1</div>
								<div class="description">Day</div>
							</div>
						</div>
					
				</div>
				<div class="festival diwali">

					<div class="titles">
					<h1><span>Festival</span><span>Of Lights</span><span>Diwali</span></h1>
					</div>
					<div class="video">
						<video src="https://westridgein.s3.us-east-1.amazonaws.com/media/restoration.mp4" autoplay loop muted playsinline></video>
					</div>
					
					
				</div>	
				
				<div class="festival kali">

					<div class="titles">
					<h1><span>Kali Pooja</span><span>The Day of</span><span>Redemption</span></h1>
					</div>
					<div class="image">
						<img src="https://westridgein.s3.us-east-1.amazonaws.com/media/goddess-kali.png" />
					</div>
				</div>

				<div class="festival halloween">

					<div class="titles">
					<h1><span>Halloween</span><span>Festival of</span><span>Sounds</span></h1>
					</div>
					<div class="image">
						<img src="https://westridgein.s3.us-east-1.amazonaws.com/media/goddess-kali.png" />
					</div>
					
					
				</div>	

				*/