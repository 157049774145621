import Node from "./node"
import DataArray from "./data-array"


class Analyser extends Node {

	constructor(...args) {
		super(...args)

		const { res = 100 } = this.config
		const data = (new DataArray(res)).fill(0)
		const peaks = (new DataArray(res)).fill(0)

		this.define({
			data: () => data,
			peaks: () => peaks
		})
	}

	init() {
		const ctx = this.ctx

		const methods = {
			ByteFrequency: "getByteFrequencyData",
			TimeDomain: "getByteTimeDomainData"
		}

		var _bars, _m, _height, _bin_size, _dataBuf

		const data = this.data, peaks = this.peaks, _velocities = []
		const analyser = this.node = ctx.createAnalyser()

		//var audioSource = ctx.createMediaElementSource( this.elem )
		//audioSource.connect( analyser )
		//analyser[m](dataBuf)
		//analyser.connect( ctx.destination )

		const setProps = this.reset = () => {
			const { res = 100, fft = 2048, height = 1,
					maxdec = -10, mindec = -90, method = 'ByteFrequency', smoothing = 0.8 } = this.config

					

			_height = height
			_m = methods[method] || methods.ByteFrequency
			_bars = Math.round(Math.abs(res))

			data.length = peaks.length = _bars

			analyser.fftSize = fft <= 128 ? 128 : fft
			analyser.smoothingTimeConstant = smoothing
			analyser.maxDecibels = maxdec
			analyser.minDecibels = mindec

			_dataBuf = new Uint8Array(analyser.frequencyBinCount)
			_bin_size = Math.floor(_dataBuf.length / _bars)
		}
		setProps()

		this._tick = (time) => {
			analyser[_m](_dataBuf)
			let sum = 0, psum = 0
			for (let i = 0; i < _bars; i++) {
				let avg = _dataBuf[(i * _bin_size)]
				let d = (avg / 256) * _height
                if (i >= 0 && i <= _bars)
                    data[i] = d
                    sum += d
                    let pk = peaks[i] || 0
					const spd = _velocities[i] || 0.1
                    _velocities[i] = spd * 1.1

                    if (pk > 0) {
                        pk = pk - Math.floor(spd)
                        if (pk < 0) { pk = 0 }
                    }
                    if (pk < d) {
                        pk = d
                        _velocities[i] = 0.1
                    }
                    peaks[i] = pk
                    psum += pk
			}
            data.average = sum / _bars
            data.sum = sum
			peaks.average = psum / _bars
            peaks.sum = psum
		}

	}
	tick(time) {
		this._tick && this._tick(time)
	}


}

export default Analyser
