import { rand } from '../../../utils/ui-utils'
//import Proton from "proton-engine/src/index";


class Shape {
	constructor(conf = {}, parent, canvas) {
		this.conf = conf
		this.parent = parent;
		this.canvas = canvas;

		Object.assign(this, conf)
		
	}

	set children(children) {
		this._children = children.map(node => {
			return new Shape[node.type](node, this)
		})
	}

	get children() { 
		return this._children || []
	}

	draw(time, ctx, context) {
		this.children.forEach(child => child.draw(time, ctx, context))
	}
}


export class Rect extends Shape {
	

	draw(time, ctx, context) {

		super.draw(time, ctx, context)

		const { x, y, width, height, color = "#fff" } = this.conf

		ctx.fillStyle = color
		ctx.fillRect(x, y, width, height)
		
	}

	tick() {
		
	}
}


export class Spectrum extends Shape {

	draw(time, ctx, context) {

		//super.draw(time, ctx, context)
		//console.log("visualizer draw", context)

		const { x, y, width, height, color = "#fff" } = this;

		if (!context.audio) { return }

		const analyser = context.audio.analyser
		

		const { data, peaks } = analyser

		const { length } = data

		const barWidth = 2

		for (let i = 0; i < length; i++) {
			const value = data[i]
			const peak = peaks[i]

			ctx.fillStyle = color
			ctx.fillRect(x + i * (barWidth + 1), (y + height - (value * height)), barWidth, value * height)
			ctx.fillRect(x + i * (barWidth + 1), y + height - peak * 100, barWidth, 1)
		}

	}	

	tick(time, context) {
		
	}
}





export class Visualizer extends Shape {

	draw(time, ctx, context) {

		//super.draw(time, ctx, context)
		//console.log("visualizer draw", context)

		const { x, y, width, height, color = "#fff" } = this;

		if (!context.audio) { return }

		const analyser = context.audio.analyser
		

		const { data, peaks } = analyser

		const { length } = data

		for (let i = 0; i < length; i++) {
			const value = data[i]
			const peak = peaks[i]

			//ctx.fillStyle = color
			//ctx.fillRect(x + i * 2, y, 1, value * 100)
			//ctx.fillRect(x + i * 2, y + peak * 100, 1, 2)
		}

	}	

	tick(time, context) {
		
	}
}

/*
  --color-day: #fffef8;
  --color-ice: #97dcf1;
  --color-water: #4fcdf4;
  --color-fire: #ff5230;
  --color-earth: #702d22;
  --color-nature: #379f31;
  --color-orange: #ff6a0d;
  --color-red: #ff2d2d;
  --color-heart: #c5284f;
  --color-green: #7bff3e;
  --color-autumn: #f5d235;
  --deepsea: #3820b2; // 385dff

    --color-nature: #417a3e;
  --color-orange: #ff6a0d;
  --color-red: #ff2d2d;
  --color-heart: #c5284f;
  --color-green: #45b15c;

  

  <path class="cls-1" d="M59.85,2.16c-27.17,11.89-10.79,11.55-39.48,0C-12.82-11.8-5.12,52.64,41.08,67.98,81.75,51.44,94.73-12.49,59.85,2.16Z"/>

  <path class="cls-1" d="M68.52,0c-7.33,0-14.39,2.8-19.74,7.66C43.42,2.8,36.36,0,29.03,0,13.02,0,0,13.02,0,29.03c0,8.67,3.92,16.61,11.34,22.97,8.43,7.23,31.41,26.77,31.41,26.77,1.68,1.43,3.82,2.21,6.02,2.21s4.34-.79,6.02-2.21c0,0,22.99-19.54,31.42-26.77,7.42-6.36,11.34-14.3,11.34-22.97,0-16.01-13.02-29.03-29.03-29.03Z"/>

*/

//const colors = ["#ffffff",  "#ff5230", "#ff6a0d", "#f5d235", "#4fcdf4", "#417a3e", "#45b15c"]

const colors = ["#ffffff",  "#ff5230", "#ff6a0d", "#f5d235", "#4fcdf4"]


const heartPath = `M59.85,2.16c-27.17,11.89-10.79,11.55-39.48,0C-12.82-11.8-5.12,52.64,41.08,67.98,81.75,51.44,94.73-12.49,59.85,2.16Z`
const threePointHeart = new Path2D("M10 10 h 80 v 80 h -80 Z");

const hearts = {
	threePointHeart,
}

function drawHeart(ctx) {
    ctx.moveTo(75, 40);
    ctx.bezierCurveTo(75, 37, 70, 25, 50, 25);
    ctx.bezierCurveTo(20, 25, 20, 62.5, 20, 62.5);
    ctx.bezierCurveTo(20, 80, 40, 102, 75, 120);
    ctx.bezierCurveTo(110, 102, 130, 80, 130, 62.5);
    ctx.bezierCurveTo(130, 62.5, 130, 25, 100, 25);
    ctx.bezierCurveTo(85, 25, 75, 37, 75, 40);
}





class Particle {

	constructor({ id, x, y, width, height, index }, parent, canvas) {

		this.parent = parent;
		this.index = index;
		this.key = rand(0, parent.count || 100);
		this.height = height;
		// this.y = Math.random() * height/3+(parent.height)*2/3-100;
		this.x = x + Math.random() * width;
		this.y = y + Math.random() * height/3 //+(parent.height)*2/3-100;
		this.vx = (Math.random() * 10 - 5)/20;
		this.vy = (Math.random() * 10 - 5)/100;
		this.gravity = 0.3;
		
		this.id = id;
		this.life = 0;
		this.maxLife = Math.random() < 0.1 ? Math.random() * 150 : Math.random() * 40;

		this.color = colors[rand(0, colors.length - 1)];

		this.size = Math.random() * 3;

		this.factor = Math.random() 
		this.scaleFactor = rand(1,3);

		const shape = Math.random() < 0.3 ? parent.shape : 'arc';
		this.shape = hearts[shape] || 'arc';
	}

	draw(ctx, time, context) {
		this.x += this.vx;
		this.y += this.vy;
		if (Math.random() < 0.1) {
			this.vx = Math.random() * 10 - 5;
			this.vy = -2;
		}

		const {analyser, beat} = context.audio || {}

		this.life++;
		if (this.life >= this.maxLife) {
			this.destroy();
		}

		let { x, y, size, key, shape, scaleFactor, height } = this;
		//x = x + Math.sin(time  / 100 + this.factor) //

		const i = this.index || 0

		if (analyser && beat) {
			
			const { data, peaks } = analyser

			const { length } = data

			
			const d = data[i] || peaks[key] || 0
			const beatDdata = beat.data || []
			//x = x * d
			//console.log(size, ((data[i] + 1) * 0.2))
			size = size + (Math.random() < 0.4 ? 0 : ((beatDdata[key] || 0) * 50) + (d * 50))

			//y = y * (d + 0.3)

			const ch = ctx.canvas.height
			y = (y > ch ? ch - (y % ch) : y) 
			
		}

		ctx.globalCompositeOperation = 'screen';
		ctx.fillStyle = this.color;
		ctx.strokeStyle = this.color;
		ctx.lineWidth = 1.2;

		if (shape === 'arc') {
			ctx.beginPath();
			ctx.arc(x, y, size/scaleFactor, 0, 2 * Math.PI, false);
			Math.random() > 0.5 ? ctx.fill() : ctx.stroke()
		} else {
			ctx.save();
			ctx.beginPath();
			ctx.translate(x, y);
			ctx.scale(size/50, size/50);  
			//ctx.fill(shape);
			
			drawHeart(ctx)
			Math.random() > 0.5 ? ctx.fill() : ctx.stroke()
			//ctx.fill();
			ctx.restore();
		}
		
		
		
	};
	destroy() {
		delete this.parent.particles[this.id]
	}
}



export class Particles extends Shape { 
	constructor(conf = {}, parent, canvas) {
		super(conf, parent, canvas)

		this.particles = {}

		this.generate = this.createParticles()
	}


	createParticles() {
		
		let particleIndex = 0;
		const { particles, count = 100 } = this

		return () => {
			for (var i = 0; i < count; i++) {
				particles[particleIndex] = new Particle({
					id: particleIndex,
					width: this.width,
					height: this.height,
					x: this.x,
					y: this.y,
					index: i
				}, this);
				particleIndex += 1;
			}
		}
		
	}

	draw(time, ctx, context) {
		super.draw(time, ctx, context)

		this.generate(time, ctx, context)

		//console.log(context.audio.beat.data)

		for (let i in this.particles) {
			this.particles[i].draw(ctx, time, context);
		}
	}

}






const HEART = `M69.11,12.34c-6.91,0-13.57,2.64-18.61,7.22-5.04-4.59-11.7-7.22-18.61-7.22-15.09,0-27.36,12.27-27.36,27.36,0,8.17,3.7,15.66,10.69,21.65,7.95,6.81,29.61,25.23,29.61,25.23,1.58,1.34,3.6,2.08,5.67,2.08s4.09-.74,5.67-2.08c0,0,21.67-18.42,29.62-25.23,6.99-6,10.69-13.48,10.69-21.65,0-15.09-12.28-27.36-27.36-27.36Z`


export class Harmony extends Shape { 
	constructor(conf, parent, canvas) {
		super(conf, parent, canvas)

		this.hearts = {}

		this.createHearts()
	}


	createHearts() {
		
		this.symbol = new Path2D(HEART);
		
	}

	draw(time, ctx, context) {
		super.draw(time, ctx, context)

		const { x, y,  color = "#fff" } = this;

		const { width, height } = ctx.canvas

		ctx.save();
		ctx.translate(width - 70, height - 80);
		ctx.scale(0.4,0.4);

		const x1 = Math.sin(time / 100) * 8
		const x2 = Math.cos(time / 100) * -5
		const x3 = Math.sin(time / 200) * -7
		const x4 = Math.cos(time / 200) * 15

		ctx.strokeStyle = '#000000'
		ctx.fillStyle = '#EF4136'
		ctx.save();
		ctx.translate(x2, x1);
		ctx.fill(this.symbol);
		//ctx.stroke(this.symbol);
		ctx.restore();

		ctx.fillStyle = '#109D49'
		ctx.save();
		ctx.translate(x3, x2);
		ctx.fill(this.symbol);
		//ctx.stroke(this.symbol);
		ctx.restore();

		ctx.fillStyle = '#79C7EF'
		
		ctx.save();
		ctx.translate(x1, x4);
		ctx.fill(this.symbol);
		//ctx.stroke(this.symbol);
		ctx.restore();

		ctx.restore();
		ctx.restore();


	}

}





export class Protons extends Shape { 
	constructor(conf, parent, canvas) {
		super(conf, parent, canvas)

		this.protons = {}
		this.particleTrail = {};

		this.proton = this.createProtons()

		

		const renderer = this.createRenderer();
    	this.proton.addRenderer(renderer);

		document.addEventListener("mousedown", this.handleMouseDown.bind(this));
		document.addEventListener("mousemove", this.handleMouseMove.bind(this));
		document.addEventListener("mouseup", this.handleMouseUp.bind(this));

	}
	createProtons(ctx) {

		const canvas = this.canvas

		const proton = new Proton();
		const emitter = new Proton.Emitter();
		emitter.damping = 0.008;
		emitter.rate = new Proton.Rate(7);

		emitter.addInitialize(new Proton.Mass(1));
		emitter.addInitialize(new Proton.Radius(14));
		emitter.addInitialize(
		new Proton.Velocity(
			new Proton.Span(1.5),
			new Proton.Span(0, 300),
			"polar"
		)
		);

		this.mouseInfo = {
			x: 1003 / 2,
			y: 610 / 2,
		};

		this.attractionBehaviour = new Proton.Attraction(this.mouseInfo, 10, 1200);
		this.crossZoneBehaviour = new Proton.CrossZone(
			new Proton.RectZone(-100, -100, canvas.width+200, canvas.height+200),
				"bound"
		);
		emitter.addBehaviour(new Proton.Color("random"));
		emitter.addBehaviour(this.attractionBehaviour, this.crossZoneBehaviour);
		emitter.addBehaviour(new Proton.RandomDrift(1, 10, 0.15));

		emitter.p.x = canvas.width / 2;
		emitter.p.y = canvas.height / 2;
		emitter.emit("once");
		proton.addEmitter(emitter);

		return proton
	}

	createRenderer() {
		const canvas = this.canvas.canvas


		const particleTrail = this.particleTrail;


		const renderer = new Proton.CanvasRenderer(canvas);

		renderer.onProtonUpdate = () => {
			//ctx.fillStyle = "rgba(255, 255, 255, 0.05)";
			//ctx.fillRect(0, 0, canvas.width, canvas.height);
		};

		


		renderer.onParticleUpdate = (particle) => {
			//console.log(particle)
			if (!particleTrail[particle.id]) {
				particleTrail[particle.id] = [];
			}
			particleTrail[particle.id].push({
				x: particle.p.x,
				y: particle.p.y,
				color: particle.color,
			});
			if (particleTrail[particle.id].length > 20) {
				particleTrail[particle.id].shift();
			}
		};

		/*
		renderer.onParticleUpdate = (particle) => {
			ctx.beginPath();
			ctx.strokeStyle = particle.color;
			ctx.lineWidth = 1;
			ctx.moveTo(particle.old.p.x, particle.old.p.y);
			ctx.lineTo(particle.p.x, particle.p.y);
			ctx.closePath();
			ctx.stroke();
		};
		*/

		return renderer;
	}

	draw(time, ctx, context) {

		super.draw(time, ctx, context)

		this.proton.update();	
		const trail = this.particleTrail

		for (let i in trail) {
			
			const particles = trail[i];
			ctx.strokeStyle = particles[0].color//`rgba(0, 0, 0, ${(j / 100)})`;
			
			for (let j = 1; j < particles.length; j++) {
				ctx.save()
				ctx.globalAlpha = j / particles.length;
				ctx.beginPath();
				
				ctx.moveTo(particles[Math.abs(j-1)].x, particles[Math.abs(j-1)].y);
				
				ctx.lineTo(particles[j].x, particles[j].y);
				
				ctx.stroke();
				ctx.restore()
			}
			
			
			
		}

		//console.log(this.particleTrail)

		//this.proton.update();	
	    //this.proton.stats.update(2);

		//ctx.fillStyle = color
		//ctx.fillRect(300, 300, 100, 100)
		
	}

	handleMouseDown(e) {
		this._mousedown = true;
		this.attractionBehaviour.reset(this.mouseInfo, 0, 0);
		this.handleMouseMove(e);
	}

	handleMouseMove(e) {
		if (!this._mousedown) {
			var _x, _y;
			if (e.pageX || e.pageX === 0) {
				_x = e.screenX;
				_y = e.screenY ;
			} else if (e.offsetX || e.offsetX === 0) {
				_x = e.offsetX;
				_y = e.offsetY;
			}

			this.mouseInfo.x = _x;
			this.mouseInfo.y = _y;
		}
	}

	handleMouseUp(e) {
		this._mousedown = false;
		this.attractionBehaviour.reset(this.mouseInfo, 10, 1200);
	}
}


Shape.rect = Rect
Shape.visualizer = Visualizer
Shape.spectrum = Spectrum
Shape.particles = Particles
Shape.harmony = Harmony
Shape.protons = Protons



export default function createNodes(canvas, conf) {
	const nodes = conf.nodes.map(node => {
		return new Shape[node.type](node, null, canvas)
	})
	
	return {
		nodes,
		tick(time) {
			nodes.forEach(node => node.tick(time))
		},
		draw(time, ctx, context) {
			nodes.forEach(node => node.draw(time, ctx, context))
		}		
	}
}
