import Node from "./node"

class StereoPanner extends Node {

	init() {
		const ctx = this.ctx

		const node = this.ctx.createStereoPanner()
		this.node = node

		this.reset = () => {
			const { pan = 0 } = this.config

	        if (this.props.off) {
	            node.pan.value = 0
	        } else {
	            node.pan.value = pan
	        }

			
		}
		this.reset()
	}

	set pan(value) {
		this.node.pan.value = value
	}

}

export default StereoPanner
