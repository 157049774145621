// glitching text messages

import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

const styles = css`
  :host {
    display: inline-block;
  }
  span {

  }

`;

const template = function (elem) {
  return html`
    <span class="container"> 
		
    </span>
  `;
};


export class GlitchingText extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    config: { type: Object, attribute: false },
	repeat: { type: Number },
	speed: { type: Number }
  };

  constructor() {
    super();
    this.config = {text: ["H", "h"]};
	this.speed = 8;
	this.repeat = 1000;  
  }

  
  attachedCallback() {
	const ctr = this.qs(".container");
	const { speed, repeat, config } = this;

  
	const destroy = new ScrambleText(ctr, config.text, { 
		speed, 
		repeat, 
		onComplete: () => {

      this.fireEvent("complete", {})
		} 
	});
	
	return () => {
		destroy()
	}
  }




}

customElements.define("harmony-glitching", GlitchingText);
