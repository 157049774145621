import { Element, html, css, svg } from "../element";


import {RAF, rand, wrap, clamp, remap } from "../../../utils/ui-utils";
import ScrollAnimation from "../../../utils/ScrollTimeline";

import { inView, animate, timeline } from "motion"

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


const styles = css`
  :host {
    display: inline-block;
    padding: 0;
	transform-style: preserve-3d;
	perspective: 1000px;
  }
  .container {
	display: inline-block;
	transform-style: preserve-3d;
  }
  span {
	display: inline-block;
	transform-style: preserve-3d;
  }
  .word {
	transform-style: preserve-3d;
	
  }
`;

const template = function (elem) {
	const {text} = elem
	return html`
		<div class="container">
			${text.map((t) => html`<span class="word">${t.split("").map((l, j) => html`<span class="l">${l}</span>`)}</span> `)}
		</div>
	`;
};


export class TextIntro extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    type: { type: String },
	text: { type: Object, attribute: false },
  };

  constructor() {
    super();   
	this.text = ["text", "block"];
  }

  onClick(e) {
    
  }


  attachedCallback() {

	const container = this.qs(".container");
	const letters = this.qsa(".l");

	gsap.fromTo(letters, { 
            'will-change': 'opacity, transform', 
            opacity: 0, 
            //xPercent: () => gsap.utils.random(-200,200), 
            //yPercent: () => gsap.utils.random(-150,150);
			z: () => gsap.utils.random(0,300),
        },
        {
            ease: 'power1.inOut',
            opacity: 1,
            xPercent: 0,
            yPercent: 0,
			z: 0,
            stagger: { each: 0.05, grid: 'auto', from: 'random'},
            scrollTrigger: {
                trigger: container,
                start: 'top center',
                end: 'bottom top',
                scrub: 0.5
            }
        });

  }


}

customElements.define("harmony-text-intro", TextIntro);
