import { scaleCanvas, zoomable, clamp, createGradient, bezierGradient, RAF } from './ui-utils.js';
import {Interact} from './interact.js';

export function createPattern(width, height, color = '#fff', lineWidth = 4) {
    const canvasPattern = document.createElement('canvas');
    canvasPattern.width = width;
    canvasPattern.height = height;
    const ctx = canvasPattern.getContext('2d');

    scaleCanvas(canvasPattern, ctx, width, height);

    ctx.strokeStyle = color;
    // draw pattern to off-screen context
    for (var i = 0; i < width * 2; i += lineWidth + 2) {
        ctx.lineWidth = lineWidth;
        ctx.beginPath();
        ctx.moveTo(i, 0);
        ctx.lineTo(i - height * 2, height * 2);
        ctx.stroke();
    }
	
    return canvasPattern;
}

export default class Canvas {
    constructor(canvas, width, height) {
        this.canvas = canvas;
        this.resize(width, height)
    }
    resize(width, height, pixelRatio) {
		if (!(width && height)) {
			this._bounds = null
        	const bounds = this.bounds;
			width = bounds.width;
			height = bounds.height
		}
        
        this.ratio = scaleCanvas(this.canvas, this.ctx, width, height, pixelRatio);
    }
	get ctx() {
		if (!this._ctx) {
			this._ctx = this.canvas.getContext('2d');
		}
		return this._ctx
	}
    get width() {
        return this.bounds.width
    }
    get height() {
        return this.bounds.height
    }
    get bounds() {
        if (this._bounds) {
            return this._bounds
        }
        this._bounds = this.canvas.getBoundingClientRect();
        return this._bounds
    }
    get scroll() {
        if (!this._scroll) {
            this._scroll = { x: 0, y: 0, zoom: 1 };
        }
        return this._scroll;
    }

    clearAll(x = 0, y = 0, w = this.bounds.width, h = this.bounds.height) {
        this.ctx.clearRect(x, y, w, h);
	}


	
    interact(actions = {}) {
        return Interact(this.canvas, actions);
	}
	
    zoomable(opts = {}, scroll = this.scroll) {
        const self = this
        const bounds = {
            get width() {
                return self.bounds.width
            },
            get height() {
                return self.bounds.height
            }
        }

        return zoomable(scroll, bounds, opts);
    }
    createPattern(width, height, color = '#fff', lineWidth = 1) {
        const canvas = createPattern(width, height, color, lineWidth);
        return this.ctx.createPattern(canvas, 'repeat');
	}

	createGradient({ stops = [[0, '#000'], [1, '#fff']], x = 0, y = 0, w = this.bounds.width, h = this.bounds.height } = {}) {
		return createGradient(this.ctx, { stops, x, y, w, h })
	}

	bezierGradient({ colors = ['#000', '#fff'], x = 0, y = 0, w = this.bounds.width, h = this.bounds.height, steps = 10, alpha = false }) {
		return bezierGradient(this.ctx, { colors, steps, alpha, x, y, w, h })
	}
	
    image(src, x = 0, y = 0, w = this.bounds.width, h = this.bounds.height, ...rest) {
        return this.ctx.drawImage(src, x, y, w, h, ...rest)
	}
	
	

	roundedRect(x, y, w, h, r) {
		const { ctx } = this
		if (w < 2 * r) r = w / 2;
		if (h < 2 * r) r = h / 2;
		ctx.beginPath();
		ctx.moveTo(x+r, y);
		ctx.arcTo(x+w, y,   x+w, y+h, r);
		ctx.arcTo(x+w, y+h, x,   y+h, r);
		ctx.arcTo(x,   y+h, x,   y,   r);
		ctx.arcTo(x,   y,   x+w, y,   r);
		ctx.closePath();
	}

	
	
	plot(array, options = {}) {
		
		const { ctx } = this
		const { 
				width = this.width, 
				height = this.height, 
				startx = 0, 
				starty = 0, 
				endx = 20,
				zoomx = 1, 
				len = array.length, 
				max = Math.max(...array),
				color = 'black',
				lineWidth = 1,
				showLead = true,
				convertText = (n) => n,				
			} = options
			
		const w = width * zoomx; // only horizontal scaling for now
		const h = height

		const h2 = h / 2;
		const wl = (w - endx - startx) / len;

		const first = (h2 - array[0] / max * h2) + starty;
		let last // last item in data
		let lp // last point on plotter

		ctx.beginPath();
		ctx.lineWidth = lineWidth;
		ctx.strokeStyle = color;
		ctx.fillStyle = color;
		
		ctx.moveTo(startx, first);


		for (let j = 0; j < len; ++j) {
			let p = array[j];
			last = p;
			p = p / max * h2;
			lp = h2 - p + starty;
			ctx.lineTo(startx + j * wl, lp);
		}

		ctx.lineTo(w - endx, lp);
		ctx.stroke();
		if (!options.hideText) {
			ctx.beginPath();
			ctx.arc(w - endx, lp, 3, 0, 2 * Math.PI, false);
			ctx.fill();
			ctx.font = "11px Dosis";

			const tx = w - endx - 40;
			const ty = clamp(lp - 5, 30, h);

			ctx.fillText(convertText(last), tx, ty);
		}

		if (showLead) {
			ctx.beginPath();
			ctx.arc(w - endx, lp, 2, 0, 2 * Math.PI, false);
			ctx.fill();
		}

	}
	colorAtPoint(x, y) {
		const r = this.ratio
		return this.ctx.getImageData(x*r, y*r, 1, 1).data;
	}

}
