import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

const styles = css`
  :host {
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
	height: 100%;
  }
  .container {
    width: 100%;
	height: 100%;
	pointer-events: all;
  }
  .canvas {
	width: 100%;
	height: 100%;
  }
  
`;

const template = function (elem) {
  return html`
    <div class="container "> 
		<div class="content">
			<canvas class="canvas"></canvas>
		</div>
      
    </div>
  `;
};


export class UnitingHearts extends Element {
  static template = template;
  static styles = styles;

  static properties = {
	
    data: { type: Object },
  };

  constructor() {
    super();
    this.data = { name: "Evee" };    
  }

  async initialize(e) {

	
  }


  attachedCallback() {

	
  }





}

customElements.define("harmony-uniting-hearts", UnitingHearts);
