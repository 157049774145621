import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

import "../common/attractive-button"
import "../common/icon"
import "../common/self-destruct";

const styles = css`
  :host {
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .container {
    width: 100vw;
    height: 100vh;
	pointer-events: all;
  }
  .hidden {
	display: none;
  }
  
  .bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	z-index: 1;
  }
  .content {
	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	transform: scale(0.5);
	transform-origin: center;
	transition: transform 1.5s;
	border-radius: 48px;
	overflow: hidden;
  }
  .icon {
	width: 100px;
	height: 100px;
	margin: 40px 0;
  }
  .icon svg {
	fill: white;
	stroke:  var(--color-eternity-200);
  }
  .container {
	
  }
  .container.fullscreen .content {
	transform: scale(1);
  }
  video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
  }


  
`;

const template = function (elem) {
	const { data, fullscreen, selfDestruct } = elem;
	
	if (!data) return html`<p>No data</p>`;


	const triggerAlarm = ({ steps, step }) => {
		return html`<div class="self-destruction-warning">
			<harmony-self-destructing 
				.steps=${steps} 
				@kill=${(e) => elem.onKill(e)} 
				@destruct=${(e) => elem.onDestruct(e)}
				@cancel=${(e) => elem.onCancel(e)}
				>
			
			</harmony-self-destructing>
		</div>`
	}

  return html`
    <div class="container ${fullscreen ? 'fullscreen' : ''}"> 
		
		<div class="content">
			${selfDestruct ? triggerAlarm(selfDestruct) : ''}
			
			<video controls autoplay>
				<source src=${data.src} type="video/mp4">
				${data.track ? html`<track src=${data.track.src} kind="${data.track.kind}" srclang="${data.track.srclang}" label="English">` : ""}
			</video>
		</div>
      
	  <div class="bg"></div>
    </div>
  `;
};


export class VideoMessage extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    data: { type: Object },
	fullscreen: { type: Boolean },
	selfDestruct: { type: Object },
  };

  constructor() {
    super();
    this.data = { };    
  }

  close(e) {
	this.fullscreen = false;	
	setTimeout(() => {
		this.initializeSelfDestructSequence({ steps: 10});
	}, 2000)
 }

  initializeSelfDestructSequence({ steps }) { 
	this.selfDestruct = { steps }
  }

  async initialize(e) {

	
  }

  onCancel(e) {
	
	this.selfDestruct = null
	this.fullscreen = true
  }

  onKill(e) {
	
	setTimeout(() => {
		this.remove()
	}, 0)	
	
  }

  onDestruct(e) {

	this.remove()
  }


  attachedCallback() {

	//const h1 = this.qs(".quote");
	//const destroy = ScrambleText(h1, ["Cruelty created empaty", "Kindness creates Harmony"]);

	setTimeout(() => {
		// this.fullscreen = true
		// fullscreen be set from above
	}, 1000)
  }





}

customElements.define("harmony-video-message", VideoMessage);
