import { Element, html, css, svg } from "../element";
import ScrambleText from "../../../utils/text-scrambler";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { GradientPath } from "gradient-path";

import chroma from "chroma-js";
import {RAF, rand, wrap, clamp } from "../../../utils/ui-utils";
import { simplex3, seed } from "../../../utils/noise";
import ScrollAnimation from "../../../utils/ScrollTimeline";

seed(Math.random());

const styles = css`
  :host {
    display: block;
    padding: 0;
    width: 100%;
    height: 1400vh;
	position: relative;
	
  }

  #hearts {
	height: 0;
	position: absolute;
	top:0;
  }

  .container {
    width: 100%;
	height: 1400vh;
	pointer-events: all;
	background: var(--color-night);
	border-radius: 48px;
	
  }
  
  .stage {
	position: sticky;
	top: 0;
	width: 100%;
    height: 100vh;
	overflow: hidden;
  }

  .stage-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
    height: 100vh;
	transform-style: preserve-3d;
	perspective: 600px;

  }
  

 .intro_hearts {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
    height: 100%;
	transform-style: preserve-3d;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateZ(-2000px);
	font-family: var(--headline-font, "Inter", sans-serif);
	font-size: var(--size-xxs);
	color: var(--color-white);
	text-transform: uppercase;
	letter-spacing: 0.5rem;
	text-align: center;
  }
   .intro_hearts div {
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate3d(-50%, -50%, 0);
	  padding: 0 20px;
	  white-space: nowrap;
   }
  .intro_hearts .intro_harmony {
	transform: translate3d(-50%, -50%, 0);
	font-size: var(--size-xs);
	letter-spacing: 0.3rem;
  }
  .intro_hearts .intro_life {
	transform: translate3d(-50%, -50%, 1300px);
  }
.intro_hearts .intro_dreams {
	transform: translate3d(-50%, -50%, 2400px);
  }
  .intro_hearts .intro_knowledge {
	transform: translate3d(-50%, -50%, 3200px);
  }
.intro_hearts .intro_love {
	transform: translate3d(-50%, -50%, 4300px);
  }

.heart-container {
	width: 100%;
    height: 100vh;
	transform-style: preserve-3d;
	transform: rotateY(0deg);
}

.heart {
	position: absolute;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	z-index: 1;
	text-align: center;
	font-size: 10rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.heart_1 {
	transform: translateZ(-1000px);
}

.heart_2 {
	transform: translateZ(100px);
}

.heart_3 {
	transform: translateZ(1000px);
}

.heart_4 {
	transform: translateZ(2000px);
}
.begin {
	transform: translateZ(2500px);
	color: #fff;
	font-size: 4rem;
	letter-spacing: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100%;
	transform-style: preserve-3d;
}
.begin > div {
	flex: 0 0 auto;
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translate3d(-50%, 0, -50px);
}
.begin > div.time {
	bottom: 0px;
	transform: translate3d(-50%, 0, 100px);
	white-space: nowrap;
}
.begin > div.location {
	bottom: 0px;
	transform: translate3d(-50%, -80px, 70px);
	white-space: nowrap;
	font-size: 1rem;
}

.heart svg {
	width: 100%;
	height: 100%;
}

.heart svg use {
	fill: none;
	stroke-width: 4;
}

.heart_1 svg use {
	stroke: var(--color-nature);
}

.heart_2 svg use {
	stroke: var(--color-ice);
}

.heart_3 svg use {
	stroke: var(--color-fire);
}

.heart_4 svg {
	
}

.heart_4 use {
	stroke-dasharray: 100;
	stroke-dashoffset: 0;
	stroke-linecap: round;
}

.heart harmony-dancing-hearts {
	position: absolute;
	left: 0;
	top: 0;
}

.tgfmlogo {
	position: absolute;
	left: 50%;
	bottom: 50%;
	width: 160px;
	height: 160px;
	transform: translate3d(30px, 340px, -100px);
}

.tgfmlogo svg {
	width: 100%;
}

.eternitylogo {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 160px;
	height: 160px;
	transform: translate3d(-80px, -50px, 150px);
}
  
.eternitylogo svg {
	width: 100%;
}

.pstlogo {
	position: absolute;
	left: 50%;
	bottom: 50%;
	width: 260px;
	height: 60px;
	transform: translate3d(-130px, 150px, 200px);
}

.pstlogo svg {
	width: 100%;
}

.peachTreesLogo {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 300px;
	height: 100px;
	transform: translate3d(-150px, -20px, -200px);
}

.label {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.label svg {
	width: 100%;
	height: 100%;
}



`;




function tgfmLogo() {
	return svg`<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.03 90.03">
  <defs>
    <style>
      .tgfm .cls-1 {
        fill: #7c8c38;
      }

      .tgfm .cls-2 {
        fill: #fff;
      }

      .tgfm .cls-3 {
        fill: #009d5f;
        fill-rule: evenodd;
      }
    </style>
  </defs>
  <g class="tgfm">
  <rect class="cls-1" x="17.19" y=".23" width="11.85" height="89.8"/>
  <rect class="cls-1" x="61.09" y=".23" width="11.85" height="89.8"/>
  <path class="cls-3" d="M83.19,29.07V11.1c0-3.85-3.12-6.96-6.96-6.96H14.23c-3.85,0-6.96,3.12-6.96,6.96v18.16c0,3.05,2.44,5.52,5.47,5.58-3.03.06-5.47,2.53-5.47,5.58v9.86c0,3.09,2.5,5.59,5.59,5.59-3.09,0-5.59,2.5-5.59,5.59v10.13c0,3.85,3.12,6.96,6.96,6.96h61.99c3.85,0,6.96-3.12,6.96-6.96v-9.94c0-3.19-2.59-5.78-5.78-5.78,3.19,0,5.78-2.59,5.78-5.78v-9.48c0-3.15-2.53-5.71-5.66-5.77,3.14-.06,5.66-2.62,5.66-5.77Z"/>
  
    <path class="cls-2" d="M18.35,27.42v3.29c0,.9.33.9,1.38.9v1.86c-.45.09-.84.17-1.54.17-1.82,0-2.33-.96-2.33-2.75v-3.48h-1.1v-1.86h1.1v-2.22h2.48v2.22h1.38v1.86h-1.38Z"/>
    <path class="cls-2" d="M28.35,28.13v5.37h-2.48v-4.58c0-1.19-.39-1.6-1.29-1.6-.79,0-1.44.48-1.44,1.58v4.59h-2.48v-11.1h2.48v4.3h.03c.42-.73,1.19-1.4,2.47-1.4,1.68,0,2.72,1.01,2.72,2.82Z"/>
    <path class="cls-2" d="M34.74,31.1h2.47c-.43,1.6-1.68,2.65-3.83,2.65-2.64,0-4.24-1.74-4.24-4.22s1.57-4.22,4.1-4.22c2.72,0,4.1,1.99,4.1,4.87h-5.71c0,1.12.82,1.71,1.71,1.71s1.26-.4,1.41-.79ZM31.62,28.68h3.23c0-1.1-.67-1.66-1.61-1.66s-1.61.56-1.61,1.66Z"/>
    <path class="cls-2" d="M30.78,35.74v7.17c0,2.62-1.51,3.52-3.91,3.52s-3.68-1.3-3.96-2.58h2.48c.16.43.54.79,1.41.79,1.12,0,1.49-.59,1.49-1.91v-.47h-.03c-.31.42-1.01,1.04-2.17,1.04-2.03,0-3.34-1.61-3.34-3.91s1.3-3.91,3.34-3.91c1.16,0,1.86.62,2.17,1.04h.03v-.79h2.48ZM28.25,39.4c0-1.29-.5-2.05-1.51-2.05s-1.51.76-1.51,2.05.5,2.05,1.51,2.05,1.51-.76,1.51-2.05Z"/>
    <path class="cls-2" d="M35.89,43.93c-2.65,0-4.25-1.75-4.25-4.22s1.6-4.22,4.25-4.22,4.24,1.75,4.24,4.22-1.6,4.22-4.24,4.22ZM35.89,42.07c1.09,0,1.75-.82,1.75-2.36s-.67-2.36-1.75-2.36-1.77.82-1.77,2.36.67,2.36,1.77,2.36Z"/>
    <path class="cls-2" d="M44.87,43.93c-2.65,0-4.25-1.75-4.25-4.22s1.6-4.22,4.25-4.22,4.24,1.75,4.24,4.22-1.6,4.22-4.24,4.22ZM44.87,42.07c1.09,0,1.75-.82,1.75-2.36s-.67-2.36-1.75-2.36-1.77.82-1.77,2.36.67,2.36,1.77,2.36Z"/>
    <path class="cls-2" d="M57.88,32.59v11.1h-2.48v-.79h-.03c-.31.42-1.15,1.04-2.3,1.04-2.11,0-3.46-1.61-3.46-4.22s1.35-4.22,3.46-4.22c1.15,0,1.99.62,2.3,1.04h.03v-3.94h2.48ZM55.35,39.71c0-1.55-.62-2.36-1.63-2.36s-1.63.81-1.63,2.36.62,2.36,1.63,2.36,1.63-.81,1.63-2.36Z"/>
    <path class="cls-2" d="M18.43,47.37v.34h1.38v1.86h-1.38v6.08h-2.48v-6.08h-1.18v-1.86h1.18v-.54c0-1.78.51-2.75,2.33-2.75.7,0,1.09.06,1.54.16v1.86c-1.06,0-1.38,0-1.38.93Z"/>
    <path class="cls-2" d="M24.06,49.17c-.9,0-1.33.34-1.41,1.1h-2.33c.08-1.52,1.16-2.81,3.76-2.81,2.24,0,3.48.87,3.48,3.37v3.65c0,.42.16.59.47.59v.59c-.5.19-.95.25-1.44.25-1.12,0-1.43-.53-1.43-1.09h-.03c-.51.59-1.44,1.09-2.5,1.09-1.68,0-2.61-.95-2.61-2.28,0-1.58,1.16-2.31,3.03-2.68,1.02-.2,2.03-.36,2.03-1.02,0-.47-.33-.75-1.01-.75ZM25.07,52.76v-.96c-.31.12-.7.23-1.27.36-.98.22-1.3.62-1.3,1.12,0,.65.39.93.99.93,1.07,0,1.58-.68,1.58-1.44Z"/>
    <path class="cls-2" d="M34.1,47.64v2.17h-.03c-1.27-.23-2.62.47-2.62,2.16v3.69h-2.48v-7.95h2.48v1.3h.03c.39-.88,1.07-1.44,1.94-1.44.22,0,.4,0,.68.06Z"/>
    <path class="cls-2" d="M47.23,50.29v5.37h-2.51v-4.58c0-1.2-.25-1.6-1.13-1.6-.79,0-1.29.48-1.29,1.58v4.59h-2.48v-4.58c0-1.2-.25-1.6-1.13-1.6-.78,0-1.29.48-1.29,1.58v4.59h-2.48v-7.95h2.48v1.15h.03c.42-.73,1.29-1.4,2.48-1.4,1.04,0,1.77.57,2.08,1.4h.03c.68-.79,1.38-1.4,2.67-1.4,1.6,0,2.55,1.01,2.55,2.82Z"/>
    <path class="cls-2" d="M53.63,53.25h2.47c-.43,1.6-1.68,2.65-3.83,2.65-2.64,0-4.24-1.74-4.24-4.22s1.57-4.22,4.1-4.22c2.72,0,4.1,1.99,4.1,4.87h-5.71c0,1.12.82,1.71,1.71,1.71s1.26-.4,1.41-.79ZM50.51,50.83h3.23c0-1.1-.67-1.66-1.61-1.66s-1.61.56-1.61,1.66Z"/>
    <path class="cls-2" d="M62.21,47.64v2.17h-.03c-1.27-.23-2.62.47-2.62,2.16v3.69h-2.48v-7.95h2.48v1.3h.03c.39-.88,1.07-1.44,1.94-1.44.22,0,.4,0,.68.06Z"/>
    <path class="cls-2" d="M70.01,53.32c0,1.68-1.27,2.59-3.48,2.59-2.84,0-3.93-1.27-4-2.89h2.33c.08.79.61,1.18,1.58,1.18.7,0,1.09-.23,1.09-.68,0-.68-.75-.78-2-1.06-1.35-.3-2.84-.79-2.84-2.45,0-1.44,1.24-2.55,3.48-2.55,2.55,0,3.54,1.29,3.62,2.68h-2.33c-.08-.64-.42-.98-1.23-.98-.7,0-1.06.25-1.06.64,0,.56.5.57,1.72.84,1.57.34,3.12.87,3.12,2.67Z"/>
    <path class="cls-2" d="M70.57,45.74c0-.84.61-1.32,1.38-1.32.95,0,1.41.71,1.41,1.86,0,1.8-.99,3.01-2.64,3.23v-1.09c.78-.11,1.32-.73,1.32-1.37-.87,0-1.47-.48-1.47-1.32Z"/>
    <path class="cls-2" d="M35.44,62.27v5.37h-2.51v-4.58c0-1.19-.25-1.6-1.13-1.6-.79,0-1.29.48-1.29,1.58v4.59h-2.48v-4.58c0-1.19-.25-1.6-1.13-1.6-.78,0-1.29.48-1.29,1.58v4.59h-2.48v-7.95h2.48v1.15h.03c.42-.73,1.29-1.4,2.48-1.4,1.04,0,1.77.57,2.08,1.4h.03c.68-.79,1.38-1.4,2.67-1.4,1.6,0,2.55,1.01,2.55,2.82Z"/>
    <path class="cls-2" d="M40.26,61.15c-.9,0-1.33.34-1.41,1.1h-2.33c.08-1.52,1.16-2.81,3.76-2.81,2.24,0,3.48.87,3.48,3.37v3.65c0,.42.16.59.47.59v.59c-.5.19-.95.25-1.44.25-1.12,0-1.43-.53-1.43-1.09h-.03c-.51.59-1.44,1.09-2.5,1.09-1.68,0-2.61-.95-2.61-2.28,0-1.58,1.16-2.31,3.03-2.68,1.02-.2,2.03-.36,2.03-1.02,0-.47-.33-.74-1.01-.74ZM41.27,64.73v-.96c-.31.12-.7.23-1.27.36-.98.22-1.3.62-1.3,1.12,0,.65.39.93.99.93,1.07,0,1.58-.68,1.58-1.44Z"/>
    <path class="cls-2" d="M50.3,59.61v2.17h-.03c-1.27-.23-2.62.47-2.62,2.16v3.69h-2.48v-7.95h2.48v1.3h.03c.39-.88,1.07-1.44,1.94-1.44.22,0,.4,0,.68.06Z"/>
    <path class="cls-2" d="M53.59,56.54v5.84l2.33-2.68h2.96l-2.81,2.98,3.13,4.97h-2.95l-1.88-3.17-.79.82v2.34h-2.48v-11.1h2.48Z"/>
    <path class="cls-2" d="M64.43,65.23h2.47c-.43,1.6-1.68,2.65-3.83,2.65-2.64,0-4.24-1.74-4.24-4.22s1.57-4.22,4.1-4.22c2.72,0,4.1,1.99,4.1,4.87h-5.71c0,1.12.82,1.71,1.71,1.71s1.26-.4,1.41-.79ZM61.31,62.81h3.23c0-1.1-.67-1.66-1.61-1.66s-1.61.56-1.61,1.66Z"/>
    <path class="cls-2" d="M70.87,61.55v3.29c0,.9.33.9,1.38.9v1.86c-.45.09-.84.17-1.54.17-1.82,0-2.33-.96-2.33-2.75v-3.48h-1.1v-1.86h1.1v-2.22h2.48v2.22h1.38v1.86h-1.38Z"/>
  
  <circle class="cls-2" cx="31.81" cy="9.83" r="2.13"/>
  <circle class="cls-2" cx="58.65" cy="10.24" r="2.13"/>
  <path class="cls-2" d="M45.44,21.27c-6.31,0-11.81-4.04-13.68-10.06-.11-.35.09-.71.43-.82.34-.11.71.09.82.43,1.7,5.47,6.69,9.14,12.42,9.14s10.55-3.56,12.33-8.86c.12-.34.49-.53.83-.41.34.12.53.49.41.83-1.96,5.84-7.42,9.76-13.58,9.76Z"/>
	  </g>
</svg>`

}


function eternityLogo() {
	return svg`<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.81 110.07">
  <defs>
    <style>
      .eternity .cls-1 {
        fill: #00aeef;
      }

      .eternity .cls-2 {
        fill: #ef413d;
      }

      .eternity .cls-3 {
        fill: none;
        stroke: #00aeef;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
    </style>
  </defs>
  <g class="eternity">
    <circle class="cls-3" cx="45.23" cy="37.83" r="36.33"/>
  	<path class="cls-2" d="M52.09,25.76c-2.55,0-5,.97-6.86,2.66-1.86-1.69-4.31-2.66-6.86-2.66-5.56,0-10.09,4.53-10.09,10.09,0,3.01,1.36,5.77,3.94,7.98,2.93,2.51,10.92,9.3,10.92,9.3.58.5,1.33.77,2.09.77s1.51-.27,2.09-.77c0,0,7.99-6.79,10.92-9.3,2.58-2.21,3.94-4.97,3.94-7.98,0-5.56-4.53-10.09-10.09-10.09Z"/>

    <path class="cls-1" d="M5.42,96.53v.99H.99v5.42h3.94v.99H.99v5.15h4.42v.99H0v-13.54h5.42Z"/>
    <path class="cls-1" d="M14.41,110.07v-12.55h-2.44v-.99h5.87v.99h-2.44v12.55h-.99Z"/>
    <path class="cls-1" d="M30.75,96.53v.99h-4.42v5.42h3.94v.99h-3.94v5.15h4.42v.99h-5.42v-13.54h5.42Z"/>
    <path class="cls-1" d="M38.58,110.07v-13.54h3.34c.84,0,1.52.27,2.03.82.51.54.77,1.27.77,2.16v1.72c0,.62-.13,1.16-.39,1.63s-.62.82-1.09,1.06l-.02-.27c.47.19.84.49,1.1.9.26.42.4.9.4,1.46v4.06h-.99v-4.06c0-.54-.17-.98-.5-1.31s-.77-.5-1.31-.5h-2.35v5.87h-.99ZM39.58,103.21h2.35c.54,0,.98-.18,1.31-.55s.5-.84.5-1.44v-1.72c0-.6-.17-1.08-.5-1.44s-.77-.55-1.31-.55h-2.35v5.69Z"/>
    <path class="cls-1" d="M53.28,110.07v-13.54h.99l4.62,11.79-.29.05v-11.84h.99v13.54h-.99l-4.62-11.79.29-.05v11.84h-.99Z"/>
    <path class="cls-1" d="M69.37,110.07h-.99v-13.54h.99v13.54Z"/>
    <path class="cls-1" d="M79.3,110.07v-12.55h-2.44v-.99h5.87v.99h-2.44v12.55h-.99Z"/>
    <path class="cls-1" d="M92.37,110.07v-7.22l-3.47-6.32h1.03l3.14,5.72h-.43l3.14-5.72h1.03l-3.45,6.32v7.22h-.99Z"/>
  </g>
</svg>`
}



function eternityLogo2() {
	return svg`<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.81 97.06">
  <defs>
    <style>
      .eternity .cls-1 {
        fill: #00aeef;
      }

      .eternity .cls-2 {
        fill: #ef413d;
      }

      .eternity .cls-3 {
        fill: none;
        stroke: #00aeef;
        stroke-miterlimit: 10;
        stroke-width: 3px;
      }
    </style>
  </defs>
  <g class="eternity"> 
	<path class="cls-3" d="M48.41,1.5h0c14.78,0,26.78,12,26.78,26.78v26.78h-26.78c-14.78,0-26.78-12-26.78-26.78h0c0-14.78,12-26.78,26.78-26.78Z" transform="translate(34.18 -25.94) rotate(45)"/>
  	<path class="cls-2" d="M52.58,22.95c-1.5,0-2.94.57-4.03,1.56-1.09-.99-2.54-1.56-4.03-1.56-3.27,0-5.93,2.66-5.93,5.93,0,1.77.8,3.39,2.32,4.69,1.72,1.48,6.42,5.47,6.42,5.47.34.29.78.45,1.23.45s.89-.16,1.23-.45c0,0,4.7-3.99,6.42-5.47,1.51-1.3,2.32-2.92,2.32-4.69,0-3.27-2.66-5.93-5.93-5.93Z"/>

    <path class="cls-1" d="M5.42,83.52v.99H.99v5.42h3.94v.99H.99v5.15h4.42v.99H0v-13.54h5.42Z"/>
    <path class="cls-1" d="M14.41,97.06v-12.55h-2.44v-.99h5.87v.99h-2.44v12.55h-.99Z"/>
    <path class="cls-1" d="M30.75,83.52v.99h-4.42v5.42h3.94v.99h-3.94v5.15h4.42v.99h-5.42v-13.54h5.42Z"/>
    <path class="cls-1" d="M38.58,97.06v-13.54h3.34c.84,0,1.52.27,2.03.82.51.54.77,1.27.77,2.16v1.72c0,.62-.13,1.16-.39,1.63s-.62.82-1.09,1.06l-.02-.27c.47.19.84.49,1.1.9.26.42.4.9.4,1.46v4.06h-.99v-4.06c0-.54-.17-.98-.5-1.31s-.77-.5-1.31-.5h-2.35v5.87h-.99ZM39.58,90.2h2.35c.54,0,.98-.18,1.31-.55s.5-.84.5-1.44v-1.72c0-.6-.17-1.08-.5-1.44s-.77-.55-1.31-.55h-2.35v5.69Z"/>
    <path class="cls-1" d="M53.28,97.06v-13.54h.99l4.62,11.79-.29.05v-11.84h.99v13.54h-.99l-4.62-11.79.29-.05v11.84h-.99Z"/>
    <path class="cls-1" d="M69.37,97.06h-.99v-13.54h.99v13.54Z"/>
    <path class="cls-1" d="M79.3,97.06v-12.55h-2.44v-.99h5.87v.99h-2.44v12.55h-.99Z"/>
    <path class="cls-1" d="M92.37,97.06v-7.22l-3.47-6.32h1.03l3.14,5.72h-.43l3.14-5.72h1.03l-3.45,6.32v7.22h-.99Z"/>
  </g>
</svg>`
}



function paperStLogo() {
	return svg`
	<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.85 68.97">
  <defs>
    <style>
      .paperst .cls-1 {
        fill: #fff;
      }
    </style>
  </defs>
  <g class="paperst">
    <path class="cls-1" d="M25.47,0c7.67,0,13.47,4.49,13.52,10.79.05,6.15-5.95,10.44-13.47,10.44h-14.53v11H0V0h25.47ZM24.42,13.77c4.54,0,4.44-6.3-.05-6.3h-13.37v6.3h13.42Z"/>
    <path class="cls-1" d="M54.59.05h6.25l22.45,32.18h-11.75l-2.88-4.29h-21.89l-2.88,4.29h-11.75L54.59.05ZM51.91,20.48h11.6l-5.8-8.62-5.8,8.62Z"/>
    <path class="cls-1" d="M110.64,0c7.67,0,13.47,4.49,13.52,10.79.05,6.15-5.95,10.44-13.47,10.44h-14.53v11h-11V0h25.47ZM109.58,13.77c4.54,0,4.44-6.3-.05-6.3h-13.37v6.3h13.42Z"/>
    <path class="cls-1" d="M128.7,32.23V.05h38.09v7.52h-27.09v4.74h24.06v7.72h-24.06v4.69h27.09v7.52h-38.09Z"/>
    <path class="cls-1" d="M171.84,32.23V0h25.47c7.67,0,13.47,4.49,13.52,10.79.05,4.19-2.72,7.52-6.86,9.23l8.78,12.21h-11.55l-8.02-11h-10.34v11h-11ZM182.83,13.77h13.42c4.54,0,4.44-6.3-.05-6.3h-13.37v6.3Z"/>
  
    <path class="cls-1" d="M58.44,57.72c1.46,2.42,6.1,3.58,9.33,3.73,3.43.2,9.13-.05,9.53-2.22.35-2.27-3.68-2.88-7.47-3.33-7.52-.91-19.22-1.31-19.22-9.89,0-7.31,9.89-10.29,17.66-10.29,7.21,0,17.35,2.02,19.22,8.78l-9.08,1.77c-.66-2.42-4.99-3.23-8.22-3.48-3.08-.25-7.92.2-8.12,2.47-.2,2.37,4.49,2.88,8.27,3.33,7.52.91,18.67,1.56,18.67,10.34,0,7.16-9.79,10.04-19.32,10.04-7.21,0-16.19-1.36-20.33-8.78l9.08-2.47Z"/>
    <path class="cls-1" d="M90.78,36.29h42.78v7.52h-15.89v24.67h-11v-24.67h-15.89v-7.52Z"/>
    <path class="cls-1" d="M138.61,68.47v-32.23h25.47c7.67,0,13.47,4.49,13.52,10.79.05,4.19-2.72,7.52-6.86,9.23l8.78,12.21h-11.55l-8.02-11h-10.34v11h-11ZM149.61,50.01h13.42c4.54,0,4.44-6.31-.05-6.31h-13.37v6.31Z"/>
    <path class="cls-1" d="M182.8,68.47v-32.19h38.09v7.52h-27.09v4.74h24.06v7.72h-24.06v4.69h27.09v7.52h-38.09Z"/>
    <path class="cls-1" d="M225.94,68.47v-32.19h38.09v7.52h-27.09v4.74h24.06v7.72h-24.06v4.69h27.09v7.52h-38.09Z"/>
    <path class="cls-1" d="M269.08,36.29h42.78v7.52h-15.89v24.67h-11v-24.67h-15.89v-7.52Z"/>
  </g>
</svg>
	`
}


function peachTreesLogo() {
	return svg`
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.8 56.63">
  <defs>
    <style>
      .peachtrees .cls-1 {
        fill: #fff;
      }
    </style>
  </defs>
  <g class="peachtrees">
  <path class="cls-1" d="M4.13,39.94v16.7H0V13.02h4.13v5.21h.13c1.84-3.11,5.27-6.03,10.92-6.03,7.87,0,14.22,6.35,14.22,17.02s-6.35,16.95-14.22,16.95c-5.65,0-9.02-2.79-10.92-6.22h-.13ZM4.13,29.21c0,8.13,4.32,13.33,10.54,13.33,6.73,0,10.41-6.09,10.41-13.33s-3.68-13.4-10.41-13.4c-6.22,0-10.54,5.21-10.54,13.4Z"/>
  <path class="cls-1" d="M33.59,29.21c0-10.35,6.09-17.02,15.43-17.02s14.6,6.54,14.6,15.56c0,.89,0,1.78-.06,2.29h-25.65c.06,6.6,3.55,12.7,11.17,12.7,6.73,0,9.27-4.57,9.91-7.24h4.19c-1.59,5.9-6.03,10.79-14.16,10.79-9.9,0-15.43-7.24-15.43-17.08ZM37.91,26.41h21.33c0-5.78-3.75-10.67-10.41-10.67-6.29,0-10.6,4.63-10.92,10.67Z"/>
  <path class="cls-1" d="M67.75,37.21c0-6.98,4.95-9.14,13.33-10.67,5.33-.95,8.76-1.33,8.76-5.21,0-3.3-2.1-5.52-7.37-5.52-6.22,0-8.51,2.22-8.83,6.98h-4.32c.32-5.71,3.87-10.6,13.33-10.6,5.97,0,11.24,2.54,11.24,10.29v16.19c0,2.6.38,3.81,1.78,3.81.32,0,.51,0,.89-.06v2.98c-.7.13-1.4.25-2.16.25-2.67,0-4.32-1.08-4.51-5.14h-.13c-2.29,3.68-6.1,5.71-11.43,5.71-6.41,0-10.6-3.62-10.6-9.02ZM89.85,33.27v-6.03c-.95,1.33-4.38,2.09-8.25,2.73-6.29,1.14-9.52,2.73-9.52,6.86,0,3.75,2.09,5.78,6.79,5.78,6.67,0,10.98-3.49,10.98-9.33Z"/>
  <path class="cls-1" d="M115.31,15.87c-7.94,0-11.17,6.73-11.17,13.33s3.24,13.46,11.17,13.46c6.79,0,9.33-4.89,9.59-8.83h4.32c-.44,6.98-6.03,12.38-13.91,12.38-9.27,0-15.49-6.86-15.49-17.02s6.22-16.95,15.49-16.95c7.87,0,13.14,5.27,13.91,12.13h-4.32c-.25-3.62-2.86-8.51-9.59-8.51Z"/>
  <path class="cls-1" d="M149.03,15.87c-6.16,0-10.16,4.32-10.16,9.08v20.44h-4.13V0h4.13v17.65h.13c1.78-2.67,5.27-5.4,11.05-5.4s10.6,3.24,10.6,10.41v22.73h-4.13v-21.84c0-4.13-1.9-7.68-7.49-7.68Z"/>
  <path class="cls-1" d="M169.28,13.02V2.22h4.13v10.79h6.6v3.62h-6.6v20.44c0,4,.95,4.95,4.13,4.95,1.02,0,1.52-.06,2.48-.13v3.49c-1.27.19-2.03.25-3.05.25-4.76,0-7.68-1.59-7.68-8.63v-20.38h-4.76v-3.62h4.76Z"/>
  <path class="cls-1" d="M189.86,13.02v6.6h.13c1.59-4.25,4.57-7.37,9.21-7.37,1.14,0,1.97.06,2.79.19v4.06c-.7-.13-1.4-.19-1.97-.19-5.9,0-10.16,4.7-10.16,10.98v18.09h-4.13V13.02h4.13Z"/>
  <path class="cls-1" d="M203.32,29.21c0-10.35,6.09-17.02,15.43-17.02s14.6,6.54,14.6,15.56c0,.89,0,1.78-.06,2.29h-25.65c.06,6.6,3.55,12.7,11.17,12.7,6.73,0,9.27-4.57,9.91-7.24h4.19c-1.59,5.9-6.03,10.79-14.16,10.79-9.9,0-15.43-7.24-15.43-17.08ZM207.64,26.41h21.33c0-5.78-3.75-10.67-10.41-10.67-6.29,0-10.6,4.63-10.92,10.67Z"/>
  <path class="cls-1" d="M237.54,29.21c0-10.35,6.09-17.02,15.43-17.02s14.6,6.54,14.6,15.56c0,.89,0,1.78-.06,2.29h-25.65c.06,6.6,3.55,12.7,11.17,12.7,6.73,0,9.27-4.57,9.91-7.24h4.19c-1.59,5.9-6.03,10.79-14.16,10.79-9.9,0-15.43-7.24-15.43-17.08ZM241.86,26.41h21.33c0-5.78-3.75-10.67-10.41-10.67-6.29,0-10.6,4.63-10.92,10.67Z"/>
  <path class="cls-1" d="M297.8,36.44c0,5.9-4.7,9.78-12.7,9.78-9.02,0-14.03-4.95-14.22-11.43h4.38c.25,4.44,3.11,7.81,9.9,7.81,6.22,0,8.32-2.86,8.32-6.03,0-4.76-4.83-5.27-9.33-6.35-5.97-1.46-12-2.98-12-9.27,0-5.27,4.19-8.7,11.49-8.7,8.38,0,12.38,4.51,12.95,9.84h-4.38c-.57-2.86-2.22-6.22-8.57-6.22-4.64,0-7.17,1.78-7.17,4.83,0,4.06,4.32,4.57,9.27,5.84,6.1,1.46,12.06,3.17,12.06,9.91Z"/>
	  </g>
</svg>
	`
}


function Logos() {
	return svg`
		<svg id="hearts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="0" height="0">
		<defs>
			<g id="maram">
					<polyline class="cls-2" points="43.61 82.53 43.22 68.59 16.01 34.06"/>
					<polyline class="cls-2" points="39.42 63.03 53.26 32.77 66.42 27.65"/>
					<line class="cls-2" x1="48.87" y1="41.97" x2="30.17" y2="16.26"/>
					<line class="cls-2" x1="38.87" y1="29.61" x2="50.55" y2="13.14"/>
					<line class="cls-2" x1="43.71" y1="22.35" x2="40.37" y2="7.52"/>
					<polyline class="cls-2" points="43.06 54.91 56.4 49.49 60.85 43.21 74.05 40.46"/>
					<line class="cls-2" x1="35.36" y1="58.13" x2="17.3" y2="53.04"/>
					<line class="cls-2" x1="52.2" y1="50.41" x2="62.22" y2="57.51"/>
					<polyline class="cls-2" points="29.43 49.73 33.32 36.76 34.26 33.47"/>
					<line class="cls-2" x1="53.26" y1="32.77" x2="60.02" y2="18.56"/>
			</g>

			<g id="narambu">				
					<polyline class="cls-1" points="43.61 7.52 43.22 21.46 16.01 55.99"/>
					<polyline class="cls-1" points="39.42 27.03 53.26 57.29 66.42 62.41"/>
					<line class="cls-1" x1="48.87" y1="48.08" x2="30.17" y2="73.8"/>
					<line class="cls-1" x1="38.87" y1="60.45" x2="50.55" y2="76.91"/>
					<line class="cls-1" x1="43.71" y1="67.7" x2="40.37" y2="82.53"/>
					<polyline class="cls-1" points="43.06 35.14 56.4 40.56 60.85 46.84 74.05 49.6"/>
					<line class="cls-1" x1="35.36" y1="31.93" x2="17.3" y2="37.01"/>
					<line class="cls-1" x1="52.2" y1="39.65" x2="62.22" y2="32.55"/>				
					<polyline class="cls-1" points="29.43 40.32 33.32 53.29 34.26 56.58"/>
					<line class="cls-1" x1="53.26" y1="57.29" x2="60.02" y2="71.49"/>
			</g>

			<g id="hrithiyam">
				  <path class="cls-2" pathLength="100" d="M24.72,74.09c-7.99-8.18-12.17-15.54-11.72-26.97,1.72-13.97,8.92-21.36,20.47-19.84,2.74.46,4.39,1.74,6.87,2.85,1.97.88,4.23,1.13,6.25.39,3.9-1.44,7.36-3.36,12.86-1.99,10.17,2.69,15.33,13.47,12.12,27.01-2.22,8.93-9.16,18.1-18.57,25.1-6.85,4.29-10.5,4.02-16.09-2.45-5.19-6.64-6.43-13.23-6.43-21.88.23-6.98,1.89-13.64,4.72-20.03,6.82-13.31,17.17-21.86,30.1-26.95,7.94-2.59,15.55-1.44,10.24,4.55-6.34,6.48-13.69,8.1-21.6,7.53,0,0-12.94-.53-23.67-9.77"/>
			</g>

			<g id="artiyan">
				<path class="cls-2" d="M61.55,14.73c-5.58,0-10.96,2.13-15.04,5.84-4.08-3.71-9.46-5.84-15.04-5.84-12.2,0-22.12,9.92-22.12,22.12,0,6.6,2.99,12.65,8.64,17.5,6.42,5.51,23.93,20.39,23.93,20.39,1.28,1.09,2.91,1.68,4.58,1.68s3.31-.6,4.58-1.68c0,0,17.52-14.89,23.94-20.39,5.65-4.85,8.64-10.9,8.64-17.5,0-12.2-9.92-22.12-22.12-22.12Z"/>
			</g>
		</defs>
	</svg>
			`

}






const template = function (elem) {
	const { data, context } = elem;
	
  const paths = Array(5).fill(0);
  

  const colors = [
    { color: "#ef413d", pos: 0 },
    { color: "#71cfeb", pos: 0.2 },
    { color: "#fec34e", pos: 0.5 },
    { color: "#f7941d", pos: 0.7 },
    { color: "#ef413d", pos: 0.9 },
  ];
	

  return html`
	${Logos()}
    <div class="container"> 
		
		<div class="stage">
			<div class="stage-inner">
				<div class="heart-container">
					<div class="intro_hearts">
  						<div class="intro_harmony">
							This is the heart of Harmony
						</div>
						<div class="intro_life">
							The tree of life
						</div>
						<div class="intro_dreams">
							The river of dreams
						</div>
						<div class="intro_knowledge">
							The tree of knowledge
						</div>
						<div class="intro_love">
							The heart of love
						</div>
					</div>
					${ /* inside the heart */ "" }
					<div class="heart of_nature heart_1 goodfarmers">
						${svg`<svg viewBox="0 0 90 90" preserveAspectRatio="xMidYMid meet">
							<use href="#maram"></use>
						</svg>`}
						<harmony-dancing-hearts .context=${context} palette="nature"></harmony-dancing-hearts>
						<div class="tgfmlogo">${tgfmLogo()}</div>
					</div>
					
					<div class="heart of_eternity heart_2 eternity">
						${svg`<svg viewBox="0 0 90 90" preserveAspectRatio="xMidYMid meet">
							<use href="#narambu"></use>
						</svg>`}
						<div class="eternitylogo">${eternityLogo()}</div>
						<harmony-dancing-hearts .context=${context}  palette="eternity"></harmony-dancing-hearts>
					</div>
					<div class="heart of_humanity heart_3 paperst">
						${svg`<svg viewBox="0 0 90 90" preserveAspectRatio="xMidYMid meet">
							<use href="#maram"></use>
						</svg>`}
						<div class="pstlogo">${paperStLogo()}</div>
						<harmony-dancing-hearts .context=${context}  palette="humans"></harmony-dancing-hearts>
					</div>
					<div class="heart of_harmony heart_4 peachtrees">
						${svg`<svg viewBox="0 0 90 90" preserveAspectRatio="xMidYMid meet">
							<g>
								<path class="cls-2" pathLength="100" d="M24.72,74.09c-7.99-8.18-12.17-15.54-11.72-26.97,1.72-13.97,8.92-21.36,20.47-19.84,2.74.46,4.39,1.74,6.87,2.85,1.97.88,4.23,1.13,6.25.39,3.9-1.44,7.36-3.36,12.86-1.99,10.17,2.69,15.33,13.47,12.12,27.01-2.22,8.93-9.16,18.1-18.57,25.1-6.85,4.29-10.5,4.02-16.09-2.45-5.19-6.64-6.43-13.23-6.43-21.88.23-6.98,1.89-13.64,4.72-20.03,6.82-13.31,17.17-21.86,30.1-26.95,7.94-2.59,15.55-1.44,10.24,4.55-6.34,6.48-13.69,8.1-21.6,7.53,0,0-12.94-.53-23.67-9.77"/>
							</g>			
						</svg>`}
						<div class="peachTreesLogo">${peachTreesLogo()}</div>
						<harmony-dancing-hearts .context=${context}  palette="humans"></harmony-dancing-hearts>
					</div>
					<div class="begin harmony">
						<div class="coming">
							ARRIVING
						</div>
						<div class="time">
							SPRING 2025
						</div>
						<div class="location">
							TO A FARMSTEAD NEAR YOU
						</div>
					</div>
				</div>
			</div>
		</div>
      
    </div>
  `;
};











export class HeartOfHarmony extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    data: { type: Object },
  };

  constructor() {
    super();
    this.data = { };  

  }



  async initialize(e) {

	
  }

  
  attachedCallback() {
	const heartContainer = this.qs(".heart-container");

	
	const harmonyHeart = this.qs(".heart.of_harmony path");

	
	const tl = ScrollAnimation(this, false);
	tl.fromTo(heartContainer, { rotateY: 0, translateZ: 2000}, 
			{ rotateY: 0, translateZ: -3000, ease: "power1.inOut" }, 0);

	//paths.forEach((path, i) => {		
		//tl.fromTo(path, { strokeDashoffset: 100 }, 
		//		{ strokeDashoffset: -100, ease: "power1.inOut" }, 0.3 + i * 0.1);
	//	})


    //if (!harmonyHeart.closest("g")) {
    //  return;
    //}


    const gp = new GradientPath({
      path: harmonyHeart,
      segments: 130,
      samples: 3,
      precision: 2, // Optional
    });
    /*
    gp.render({
      type: "path",
      fill: [
        { color: "#C6FFDD", pos: 0 },
        { color: "#FBD786", pos: 0.25 },
        { color: "#F7797D", pos: 0.5 },
        { color: "#6DD5ED", pos: 0.75 },
        { color: "#C6FFDD", pos: 1 },
      ],
      width: 8,
    });

	      #00a76f;
      #71cfeb;
      #0077c0;
      #fec34e;
      #2e3192;
      #f7941d;
      #ef413d;
	*/
    gp.render({
      type: "circle",
      fill: [
		// "#00a886", "#fec34e"
        { color: "#00a886", pos: 0 },
        { color: "#f7941d", pos: 0.1 },
        { color: "#ef413d", pos: 0.2 },
        { color: "#f7941d", pos: 0.3 },
        { color: "#fec34e", pos: 0.4 },
        { color: "#71cfeb", pos: 0.5 },
        { color: "#71cfeb", pos: 0.6 },
        { color: "#0077c0", pos: 0.7 },
		{ color: "#00a886", pos: 1 },
      ],
      width: 4,
      stroke: "#444",
      strokeWidth: 0,
    });




	return () => {
		tl.kill();
	}
	
  }





}

customElements.define("harmony-heart", HeartOfHarmony);


/*
[
		// "#00a886", "#fec34e"
        { color: "#00a886", pos: 0 },
        { color: "#f7941d", pos: 0.2 },
        { color: "#ef413d", pos: 0.3 },
        { color: "#f7941d", pos: 0.5 },
        { color: "#fec34e", pos: 0.7 },
        { color: "#71cfeb", pos: 0.9 },
        { color: "#71cfeb", pos: 0.9 },
        { color: "#0077c0", pos: 1 },
      ],
	  */








function getGradient(colors, steps) {

	return chroma.scale(colors).mode('lch').colors(steps);

}

const palettes = {
	nature: getGradient(["#00a886", "#fec34e"], 10),
	eternity: getGradient(["#00AEEF", "#6C49C4"], 10),
	humans: getGradient(['yellow', 'orange', 'red'], 10)
}

const dancingHeartStyles = css`

  :host {
    display: block;
    padding: 0;
    width: 100vw;
    height: 100vh;
	transform-style: preserve-3d;
  }

  .h {
	position: absolute;
	width: 30px;
	height: 30px;
	left: calc(50% - 25px);
	top: calc(50% - 25px);
	z-index: 1;
	transform: translate3d(0, 0, 0);
  }

  .h svg {
	width: 100%;
	height: 100%;
  }

  .h svg use {
	stroke: none;
  }

`;







function heartsTemplate(elem) { 
	const { count, palette } = elem;
	const hearts = Array(count).fill(0);

	const colors = palettes[palette];

	const art = svg`
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="0" height="0" style="position:absolute;top: 0;">
			<defs>
			<g id="artiyan">
				<path class="cls-2" d="M61.55,14.73c-5.58,0-10.96,2.13-15.04,5.84-4.08-3.71-9.46-5.84-15.04-5.84-12.2,0-22.12,9.92-22.12,22.12,0,6.6,2.99,12.65,8.64,17.5,6.42,5.51,23.93,20.39,23.93,20.39,1.28,1.09,2.91,1.68,4.58,1.68s3.31-.6,4.58-1.68c0,0,17.52-14.89,23.94-20.39,5.65-4.85,8.64-10.9,8.64-17.5,0-12.2-9.92-22.12-22.12-22.12Z"/>
			</g>
			</defs>
		</svg>
	`;

	return html`${
		hearts.map((_, i) => {
			return html`
			${art}
			<div class="h">
				${svg`<svg viewBox="0 0 90 90" preserveAspectRatio="xMidYMid meet">
						<use href="#artiyan" style=${`fill: ${colors[i]};`}></use>
					</svg>`}
			</div>`
		})
	}`
}




function particle(elem) {

	const xMax = 100
	const yMax = 600
	const zMax = 400

	let x = rand(-xMax, xMax)
	let y = rand(-yMax, 0)
	let z = rand(-zMax, zMax)

	return {
		elem,
		key: rand(0, 10),
		vy: rand(0, 20),
		vz: rand(0, 20),
		vx: rand(0, 20),

		factor: rand(350, 800),
		scaleFactor: rand(1, 3),
		radiusX: rand(100, 200),
		radiusY: rand(100, 200),
		radiusZ: rand(100, 200),

		scale: rand(1, 3),

		get x() {
			return x;
		},
		get y() {
			return y;
		},
		get z() {
			return z
		},

		set x(value) {
			x = wrap(value, -xMax, xMax);
		},
		set y(value) {
			y = wrap(value, -yMax, 0);
		},
		set z(value) {
			z = wrap(value, -zMax, zMax);
		}
	}
}






export class DancingHearts extends Element {
  static template = heartsTemplate;
  static styles = dancingHeartStyles;

  static properties = {
	data: { type: Object },
	count: { type: Number, attribute: true },
	conf: { type: Object, attribute: false },
	palette: { type: String, attribute: true },
  };

  constructor() {
	super();
	this.data = { };  
	this.count = 10;
	this.key = rand(0, 10);
	this.palette = 'nature';
  }

  set conf(value) { 


  }


  attachedCallback() { 

	const hearts = this.qsa(".h");
	const {context, palette } = this

	const particles = [...hearts].map((h, i) => particle(h));

	const raf = new RAF((time) => {	
		const { analyser, beat } = context.audio || {};

		particles.forEach((heart, i) => {

			//heart.x += heart.vx;
			//heart.y += heart.vy;
			//heart.z += heart.vz;

			let { elem, key, x, y, z, scale, factor, scaleFactor, radiusX, radiusY, radiusZ } = heart;

			if (analyser) {
				const { data, peaks} = analyser
				const d = data[ i] || 1;
				const p = peaks[ i] || 1;

				const n = simplex3(x, y, time/1000);

				x = x + Math.sin(time / 500 * ((key + 1) / 10)) * 200
				y = y + (n * 200) - 100
				z = z + Math.cos(time / 500 * ((key + 1) / 10)) * 100

				scale = (scale + (d * 4));
			}

			elem.style.transform = `translate3d(${x}px, ${y}px,  ${z}px) scale3d(${scale},${scale},1)`;
		})

	});

	return () => {
		raf.cancel();
	}
  }

}

customElements.define("harmony-dancing-hearts", DancingHearts);







const textPathStyles = css`
	:host {
		display: block;
	}
	svg {
		width: 100%;
		height: 100%;
	}
	text {
		font-family: var(--headline-font);
		font-size: 0.02em;
		letter-spacing: 0.1;
	}
`


function textPathTemplate(elem) {
	const { text, path } = elem;
	return html`
		<div class="text-path">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 150 150">
				<defs>
					<path id="text-path" d=${path}></path>
				</defs>
				<text letter-spacing="2">
					<textPath href="#text-path" startOffset="0" fill="white">${text}</textPath>
				</text>

				
			</svg>
		</div>
	`
}



export class TextPath extends Element {
	static template = textPathTemplate;
	static styles = textPathStyles;

	static properties = {
		text: { type: String },
		path: { type: String },
	}

	constructor() {
		super();
		this.text = "Hello World";
		this.path = `M51.78,1.5c27.49.15,49.72,22.48,49.72,50s-22.39,50-50,50S1.5,79.11,1.5,51.5,23.89,1.5,51.5,1.5`
	}

	attachedCallback() {
		const textPath = this.qs("textPath");
		const path = this.qs("path");

		//const tl = ScrollAnimation(this, false);
		//tl.fromTo(textPath, { startOffset: 0 }, { startOffset: 100, ease: "power1.inOut" }, 0);
	}

}

customElements.define("harmony-text-path", TextPath);
