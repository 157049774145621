const qt = function(t) { return t*t*t*t }

class Node {


	/**
	 * @static getFreq - convert frequency from 0..1
	 *
	 * @param  {type} frequency  value representing frequency. 0 ... 1
	 * @param  {type} sampleRate the sample rate of the audio content
	 * @return {type}            frequency from 10 ... sampleRate/2
	 */
	static getFreq(frequency, sampleRate) {
		const n = sampleRate * 0.5
		const noctaves = Math.log(n / 10.0) / Math.LN2
		return Math.pow(2.0, noctaves * ((frequency || 0.4) - 1.0)) * n
	}

	static getQ(q) {
		return qt(q/2) * 1000
		//return (q - 1) * 3.402 //qt(q/2) * 1000
	}


	constructor(conf, elem, parent) {
		this.conf = conf
		this.elem = elem
		this.parent = parent
	}
	get props() {
		return this.conf.props || {}
	}
	get config() {
		return this.props.config
	}
	get name() {
		return this.conf.fname
	}
	set props(props) {
		Object.assign(this.conf.props, props)
		if (this.reset) {
			this.reset()
		}
	}
	set config(c) {
		Object.assign(this.conf.props.config, c)

		if (this.reset) {
			this.reset()
		}
	}

	define(args) {
		Object.keys(args).forEach(k => {
			Object.defineProperty(this, k, {
				configurable: true,
				get: args[k],
				set: (value) => {
					// readonly property. ignore
				}
			})
		})
	}

	get output() {
		return this.node
	}
	get input() {
		return this.node
	}

	connect(sources) {


		this.source = sources[0]
		//console.log("node source", this.source, this.source.output, this.input)

		this.source.output.connect(this.input)
	}

	disconnect() {
		if (this.source) {
			this.source.output.disconnect()
		}
	}

	destroy() {

	}

	get ctx() {
		return this.parent.constructor.ctx
	}

}

export default Node
