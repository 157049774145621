import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

import "./video-message"
import "../common/glitching"

const styles = css`
  :host {
    display: block;
    padding: 0;
	position: relative
  }
  .container {
    width: 100%;
    height: 100%;
	pointer-events: all;
	min-width: 50vw;
  }

  
  button {
    font-size: 1.5rem;
    background-color: var(--color-eternity, #000);
    pointer-events: all;
  }
  button:hover {
    background-color: red;
  }

  
  .content {
	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
  }
  .icon {
	width: 100px;
	height: 100px;
	margin: 40px 0;
  }
  .icon svg {
	fill: white;
	stroke:  var(--color-eternity-200);
  }

  .message {
	display: flex;
	flex-direction: column;
	max-height: 400px;
	max-width: 600px;
	min-height: 300px;
	min-width: 300px;
	backdrop-filter: blur(10px);
	background: rgba(143, 214, 255, 0.477);
	border-radius: 24px;
	border: 6px solid var(--color-eternity, #fff);
	box-sizing: border-box;
  }

  .header {
	height: 40px;
	flex: 0 0 auto;
	color: red;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: white;
  }
  .name {
	text-align: right;
	background: var(--color-eternity, #fff);
	padding: 6px 20px;
	border-radius: 15px 0 8px 0px;
	color: var(--color-white);
	letter-spacing: 1px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
  }
  .title {
	text-align: left;
	padding: 10px 20px;
	letter-spacing: 0px;
	color: var(--color-eternity, #fff);
  }

  .contents {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
  }

  .subtitles {
	color: var(--color-text-eternity-200, #000);
	width: 100%;
	padding: 24px;
	font-size: var(--evv-subtitles-font-size, 2.2rem);
	box-sizing: border-box;
  }


  .attachment {
	position: absolute;
	left: 0%;
	top: 0%;
	width: 100%;
	height: 100%;
  }


    .close {
	position: absolute;
	top: 2rem;
	right: 20px;
	padding: 20px;
	width: 50px;
	height: 50px;
	border: 2px solid var(--color-eternity, #000);
	border-radius: 100%;
	z-index: 100;
  }
  .self-destruction-warning {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 100;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  }

`;


const template = function (elem) {

  const { data = {}, message, attachment, fullscreen, selfDestruct } = elem;
  const { header = {}, content = [], attachments = [] } = data;

	function openAttachment(attachment) {
		return html`
				<div class="attachment">
					<harmony-video-message .data="${attachment}" .fullscreen=${fullscreen}></harmony-video-message>
				</div>	
				`
	}


	const triggerAlarm = ({ steps, step }) => {
		return html`<div class="self-destruction-warning">
			<harmony-self-destructing 
				.steps=${steps} 
					@kill=${(e) => elem.onKill(e)} 
					@destruct=${(e) => elem.onDestruct(e)}
					@cancel=${(e) => elem.onCancel(e)}
					@destroy=${(e) => elem.onDestroy(e)}
				>
			
			</harmony-self-destructing>
		</div>`
	}



	function newMessage() {
		const { messages, options } = elem.chat;
		return html`
			<div class="message new">
				<div class="header">
					<div class="name">E VII VII</div>
					<div class="title">Incoming message: ${header.subject}</div>					
				</div>
				<div class="contents">
					<div class="subtitles">
						<harmony-glitching 
							.config=${{ text: messages }} 
							.repeat=${options.repeat} 
							.speed=${options.speed}
							@complete=${(e) => elem.onComplete(e)}
						></harmony-glitching>
					</div>
				</div>
				
			</div>	
		`
	}


  return html`
    <div class="container"> 
		${selfDestruct ? triggerAlarm(selfDestruct) : ''}
		<div class="close">
				<harmony-attractive-button>
					<button @click=${(e) => elem.close(e) }>
						<harmony-icon name="close"></harmony-icon>
					</button>
				</harmony-attractive-button>				
		</div>
		<div class="content">
			${attachment ? openAttachment(attachment) : newMessage()}
		</div>
		
    </div>
  `;
};


export class Incoming extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    greeting: { type: String },
    data: { type: Object },
	opened: { type: Boolean },
	attachment: { type: Object },
	chat: { type: Object },
	fullscreen: { type: Boolean },
	selfDestruct: { type: Object },
  };

  constructor() {
    super();
    this.greeting = "Hello";
    this.data = { };
	this.chat = { messages: [], options: {} };
  }


  // all messages are compleded
  onComplete(e) {
	// if we have attachments...


	this.attachment = this.data.attachments[0];
	// once the video is loaded and starts playing...
	if (this.attachment) {
		setTimeout(() => {
			this.fullscreen = true;
		}, 10)
	}
	
  }


  onCancel(e) {
	
	this.selfDestruct = null
	this.fullscreen = true
  }

  onKill(e) {
	
	setTimeout(() => {
		//this.remove()
		this.playSound("dialogKilled");
		this.onEndOfMessage()
	}, 0)	
	
  }

  onDestruct(e) {
	this.onEndOfMessage()
  }


  // after destruction sequence has played out
  onDestroy() {
	this.playSound("dialogLeave");

	this.onEndOfMessage()
  }


  onEndOfMessage() {
	this.fireEvent({ name: "messageended", detail: {} });
	this.fireEvent({ name: "message-inactive", detail: {} });
  }


  _onKill(e) {
	
	setTimeout(() => {
		this.attachment = null;
		this.fireEvent({ name: "message-inactive", detail: {} });
	}, 0)	
	
  }

  _onDestruct(e) {

	setTimeout(() => {
		this.attachment = null;
		this.fireEvent({ name: "message-inactive", detail: {} });
	}, 0)	
  }

  _onCancel(e) { 
	this.attachment = this.data.attachments[0];

	// if no attachment, then initialize self destrc sequence
  }

  close(e) {
	this.fullscreen = false;	
	setTimeout(() => {
		this.initializeSelfDestructSequence({ steps: 10});
	}, 200)

	
 }

  initializeSelfDestructSequence({ steps }) { 
	this.selfDestruct = { steps }
  }


  attachedCallback() {


	const { header = {}, content = [], attachments = [] } = this.data;

	//const h1 = this.qs(".quote");
	//const destroy = ScrambleText(h1, ["Cruelty created empaty", "Kindness creates Harmony"]);

	const msgRef = this.qs(".subtitles");


	this.chat = {

		messages: [
		`You have an incoming message from home, sent about ${header.date}`, 		
		`Looks important, I am opening it for you`,
		`It's from Mother`,
		"_ ", "  ", " _",
		...(content || []),
		"_ ",
		`There is a video attached, I am playing it for you now`,
	], 
	options: {
			speed: 10,
			repeat: 0,
	    }
	}

	//this.attachment = attachments[0];

	//this.fireEvent({ name: "evv-message", detail: {
	//	type: 'dialog', action: 'open'
	//} });

	
	//this.fireEvent({ name: "message-active", detail: {} });

	this.playSound("dialogOpen");
	this.fireEvent({ name: "message-active", detail: {} });

  }

}

customElements.define("harmony-incoming-message", Incoming);
