import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

const styles = css`
  :host {
    display: block;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .container {
    width: 100%;
    height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
.content {
    flex: 1 1 auto;
	position: relative;
  }
  .hidden {
	display: none;
  }
  .left, .right, .mid {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }


`;

const template = function (elem) {
  return html`
    <div class="container "> 
		<div class="content">
			<div class="left">
				<slot name="left"></slot>
			</div>
			<div class="mid">
				<slot name="mid"></slot>
			</div>
			<div class="right">
				<slot name="right"></slot>
			</div>
		</div>
    </div>
  `;
};


export class Enter extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    data: { type: Object },
  };

  constructor() {
    super();
    this.data = { title: "" };    
  }

  





}

customElements.define("harmony-enter", Enter);
