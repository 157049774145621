import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

import "./incoming"

const styles = css`
  :host {
    display: block;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
	pointer-events: none;
  }
  .container {
    width: 100vw;
    height: 100vh;
		
  }
  .hidden {
	display: none;
  }
  button {
    font-size: 1.5rem;
    background-color: var(--color-eternity, #000);
    pointer-events: all;
  }
  button:hover {
    background-color: red;
  }
  .bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	z-index: 1;
  }
  .content {
	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	flex-direction: column;
  }
  .icon {
	width: 100px;
	height: 100px;
	margin: 40px 0;
  }
  .icon svg {
	fill: white;
	stroke:  var(--color-eternity-200);
  }

  .message {
	position: relative;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
  }

  .message video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
  }
   harmony-incoming-message {
	flex: 1 1 auto;
   }


`;


const template = function (elem) {

	const messageWindow = (contentType, data) => {
		switch(contentType) {
		case "text":
			return html`
				<p>${data.text}</p>
			`
		case "image":
			return html`
				<img src="${data.src}" />
			`
		case "video":
			return html`
				<harmony-incoming-message .data=${data} 
					@close=${(e)=>elem.onMessageClose(e)} 
					@messagestart=${(e)=>elem.onMessageStart(e)}
					@messageended=${(e)=>elem.onMessageClose(e)}>
				</harmony-incoming-message>
			`
		default:
			return html`
				<p>${data.html}</p>
			`
	}

	}

	const displayMessage = (message) => {
		const { data, type, contentType } = message;
		return html`
			<div class="message ${contentType} ${type}">
				${messageWindow(contentType, data)}
			</div>
		`
	}


  return html`
    <div class="container "> 
		<div class="content">
			${elem.message ? displayMessage(elem.message) : ''}
		</div>
      
	  <div class="bg"></div>
    </div>
  `;
};


export class Alert extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    greeting: { type: String },
    data: { type: Object },
	message: { type: Object },
  };

  constructor() {
    super();
    this.greeting = "Hello";
    this.data = { name: "Evee" };    
  }

  onMessageClose(e) {
	// dialog message has been closed
	this.message = null;
	//this.fireEvent({ name: "message-inactive", detail: {} }); // turn on scrolling
  }

  onMessageStart(e) {
	// dialog message has been opened
	//this.fireEvent({ name: "message-active", detail: {} }); // turn off scrolling
  }


  attachedCallback() {

	

	//const h1 = this.qs(".quote");
	//const destroy = ScrambleText(h1, ["Cruelty created empaty", "Kindness creates Harmony"]);


	const onAlert = (e) => {
		const { data, type, contentType } = e.detail;


		//destroy();
		this.message = {
			data,
			type,
			contentType
		}
	}


	document.addEventListener("hey-evv", onAlert);


	return () => {
		document.removeEventListener("hey-evv", onAlert);
	}

  }

}

customElements.define("harmony-alert", Alert);
