import { Element, html, css } from "../element";
import ScrambleText from "../../../utils/text-scrambler";

const styles = css`
  :host {
    display: block;
    padding: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .container {
    width: 100%;
    height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  .hidden {
	display: none;
  }
  .title {
	font-size: var(--size-large);
  }
  .description {
	font-size: var(--size-small);
	text-align: left;
	padding: 24px 0;
  }

`;

const template = function (elem) {
  return html`
    <div class="container "> 
		<div class="content">
			<div class="title">
				<slot name="title"></slot>
			</div>
			<div class="description">
				<slot name="content"></slot>
			</div>
		</div>
    </div>
  `;
};


export class Feature extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    data: { type: Object },
  };

  constructor() {
    super();
    this.data = { title: "" };    
  }

  





}

customElements.define("harmony-feature", Feature);
