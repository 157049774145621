import { Element, html, css, svg } from "../element";


import {RAF, rand, wrap, clamp, remap } from "../../../utils/ui-utils";
import { simplex3, seed } from "../../../utils/noise";
import ScrollAnimation from "../../../utils/ScrollTimeline";
import { Fireworks } from 'fireworks-js'
import chroma from "chroma-js";
import { render } from "lit-element";

import { inView, animate, timeline } from "motion"



const styles = css`
  :host {
    display: block;
    padding: 0;
    position: relative;
    width: 100vw;
    min-height: 200vh;
	overflow: hidden;
  }
  .container {
    width: 100vw;
    min-height: 200vh;
  }

  .intro {
	position: relative;
    width: 100vw;
    height: 200vh;
  }
  .intro-content {
	position: sticky;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
	transform-style: preserve-3d;
	perspective: 600px;
	transform-origin: center;
  }

  .titles {
	width: 100vw;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transform-style: preserve-3d;
  }


  h1 {
	font-size: var(--size-large);
	font-weight: bold;
	color: white;
	font-family: var(--headline-font);
	text-align: center;
	width: 100%;
	line-height: 1;
	text-transform: uppercase;
	transform-style: preserve-3d;
	transform: translateZ(0px);
  }

  h1 span {
	display: block;
  }
  h1 span:first-child {
	
  }
  h1 span:last-child {
	
  }

  .image {
	height: 100vh;
	aspect-ratio: 1/1;
  }
  .image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
  }

  .description {
	min-height: 100vh;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
  }

  .description-content {
	max-width: 1300px;
	font-size: var(--size-md);
	line-height: 1.2;
	color: white;
	font-family: var(--headline-font);
	text-align: center;
	padding: 40px;
	box-sizing: border-box;
  }

 
  .media {
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0px;
	width: 100vw;
	transform: translateZ(-200px) scale(1.4);
	display: flex;
	transform-style: preserve-3d;
	width: 100vw;
	height: 100vh;
  }



  .mediaitem {
	flex: 1 1 auto;
	height: 100vh;
	overflow: hidden;
	clip-path: inset(0 0 0 0 round 48px);
	border-radius: 48px;
  }

  .mediaitem img,
  .mediaitem video {
	width: 100%;
	height: 100%;
	object-fit: cover;
	clip-path: inset(0 0 0 0 round 48px);
  }

  .stats {
	position: absolute;
	bottom: -100px;
	left: 0px;
	font-size: var(--size-xs);
	
	color: var(--color-ice);
	font-family: var(--headline-font);
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content:center;
	box-sizing: border-box;
	gap: 20px;
	padding: 400px 30px 30px 30px;
	box-sizing: border-box;
	transform: translateZ(100px);
	transform-style: preserve-3d;
	text-transform: uppercase;
  }
  .stat {
	width: auto;
	padding: 10px;
	text-align: center;
	backdrop-filter: blur(15px);
	border: 2px solid var(--color-eternity);
	border-radius: 10px;
	min-width: 80px;
	width: 10%;
	letter-spacing: 1px;
  }
  .stat .stat-title {
	font-size:  var(--size-xs);
	color: var(--color-day);
	font-weight: bold;
  }

  .events {
	position: relative;
	font-size: 30px;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }

  .event {
	position: relative;
	min-height: 100vh;
	max-width: 800px;
	padding: 20px;
	width: 100%;
	transform-style: preserve-3d;
  }

  .event-summary {
	position: absolute;
	right: 0;
	top: 10%;
	z-index: 10;
	padding: 40px;
	width: 100%;
	text-align: right;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	box-sizing: border-box;
  }

  .event .title {
	font-size: var(--size-captions);
	font-family: var(--headline-font);
	color: var(--color-fire);
	flex: 0 0 auto;
	max-width: 600px;
  }
  .event .title h2 {
	margin: 0;
	padding: 0 4vw;
	line-height: 1;
	flex: 0 0 auto;
  }

  .event .time {
	font-size: 20px;
	font-family: var(--headline-font);
	font-size: var(--size-sm);
	padding: 0 4vw;
	flex: 0 0 auto;
  }

  .event-media {
	height: 100vh;
	display: flex;
	transform-style: preserve-3d;
	perspective: 600px;
  }


  .event .mediaitem {
	max-width: 70%;

  }
  .event .event-description {
	font-size: var(--size-reg);
	font-family: var(--headline-font);
	max-width: 500px;
	padding: 0 4vw;
	flex: 0 0 auto;
  }

  .event .event-description p {
	margin: 0;
	padding: 0;
  }

`;

const template = function (elem) {
	const { data } = elem;
	const { title, description, media, stats, events } = data;



	function renderStats() {
		if (!stats) return "";

		return html`
			<div class="stats">${
				stats.map((stat) => {
						return html`
							<div class="stat">
								<div class="stat-title">${stat.title}</div>
								<div class="stat-description">${stat.description}</div>
							</div>
						`
					})
			}</div>
		`
	}



	function renderMedia(item, i) {
		if (!item)	return "";

		if (item.type === "video") {
			return html`<div class="mediaitem video">
				<video src="${item.src}" autoplay loop muted playsinline></video>
			</div>`
		} else if (item.type === "image") {
			return html`<div class="mediaitem image">
				<img src="${item.src}" />
			</div>`

		}
	}



	function renderEvents() {
		if (!events) return "";

		return html`
			${events.map((event) => {
				return html`
					<div class="event">
						<div class="event-summary">
							<div class="title">
								<h2>${event.title}</h2>
							</div>
							<div class="event-description">
								<P>${event.description}</P>
							</div>
							${event.time && html`
								<div class="time">
									<span>${event.time[0]}</span>
									<span> - </span>
									<span>${event.time[1]}</span>
								</div>
							`}
						</div>
						${event.media && html`
							<div class="event-media">
								${event.media.map(renderMedia)}
							</div>
						`}						
					</div>
				`
			})}
		`
	}


	return html`
	<div class="container"> 
			<div class="intro">
				<div class="intro-content">
					<div class="titles">
						<h1>
						${title.map((t) => html`<span>${t}</span>`)}</h1>
					</div>
					
					${media && html`
							<div class="media main">
								${media.map(renderMedia)}
							</div>
					`}					
					${renderStats()}
				</div>
			</div>
			${description && html`
				<div class="description">
					<div class="description-content">
						${description}
					</div>
				</div>
			`}
			<div class="events">
					${renderEvents()}
				</div>
    </div>
	`
};


export class Festival extends Element {
  static template = template;
  static styles = styles;

  static properties = {
	data: { type: Object },
  };

  constructor() {
    super();   

	this.id = "festival";
  }

  attachedCallback() {

	const container = this.qs(".container");

	const intro = this.qs(".intro");
	const introContent = this.qs(".intro-content");
	const titles = this.qsa(".titles span");

	const media = this.qs(".media.main .mediaitem");

	const stats = this.qsa(".stat");

	const events = this.qsa(".event");
	

	events.forEach((event, i) => {
		const eventSummary = event.querySelector(".event-summary");
		const eventMedia = event.querySelectorAll(".mediaitem");
		const time = event.querySelector(".time");

		const etl = ScrollAnimation(event, { 
			debug: false, 
			start: "top bottom",
			end: "bottom top"			
		});

		etl.fromTo(eventSummary, { translateY: "0px" }, 
				{ translateY: "600px",  ease: "power1.inOut", duration: 1 }, 0);

		etl.fromTo(eventMedia, { 
				translateZ: "-100px", 
				translateY: "100%", 
				rotateZ: rand(20, -20), 
				rotateX: 40,
				rotateY: -30
		}, 
		{ 
				translateZ: "-100px", 
				translateY: "0%", 
				rotateZ: rand(-10, 10), 
				rotateX: 0,
				rotateY: 0, 
				ease: "power1.inOut", duration: 0.7, stagger: 0.1 
		}, 0);

		etl.fromTo(time, { translateY: "150px" }, 
				{ translateY: "0px",  ease: "power1.inOut", duration: 1 }, 0);

	})



	const tl = ScrollAnimation(intro, { 
		debug: false, 
		start: "top center",
		end: "bottom center",
		onUpdate: (e) => {
			//console.log("depth", e.progress)
		}
	});

	if (media) {
		tl.fromTo(media, { translateY: "20%", scale: 0.5,  rotateX: 20, rotateY: 20 }, 
			{ translateY: "0%", scale: 1.1, rotateX: 0, rotateY: 0, ease: "power1.inOut", duration: 1 }, 0);

		tl.fromTo(media.firstElementChild, { translateY: "-20%", scale: 1.4 }, 
			{ translateY: "0%", scale: 1, ease: "power1.inOut", duration: 1 }, 0);
	}

	
	tl.fromTo(introContent, {  translateY: "0%" }, 
		{  translateY: "60%", ease: "power1.inOut", duration: 1 }, 0);
	
	tl.fromTo(titles, { translateX: "20%",  rotateY: 20, opacity: 0 }, 
		{ translateX: "0%",  rotateY: -10, opacity: 1, ease: "power1.inOut", duration: 1, stagger: 0.1 }, 0);

	if (stats) {
		tl.fromTo(stats, { translateY: "200px", rotateX: 10, opacity: 0 }, 
		{ translateY: "0", rotateX: 0, opacity: 1, ease: "power1.inOut", duration: 0.5, stagger: 0.1 }, 0.2);
	}



	const ctrTl = ScrollAnimation(container, { 
		debug: false, 
		start: "top bottom",
		end: "bottom bottom",
		onUpdate: (e) => {
			//console.log("depth", e.progress)
		},
		onToggle: (e) => {
			this.fireEvent({ name: "toggle", detail: { name: this.data.name, event: e } });
		}
	});

	ctrTl.fromTo(container, { translateY: "0%" }, { translateY: "0%" }, 0)
	
		

  }


}

customElements.define("harmony-festival", Festival);



/*
const tl = ScrollAnimation(this, { 
		debug: false, 
		start: "top center",
		end: "bottom center",
		onUpdate: (e) => {
			//console.log("depth", e.progress)
		},
		onToggle: (e) => {
			console.log("toggle", e)
			this.fireEvent({ name: "toggle", detail: { name: this.data.name, event: e } });
		}
	});

	if (media) {
		tl.fromTo(media, { translateY: "20%" }, 
			{ translateY: "-20%", ease: "power1.inOut", duration: 1 }, 0);
	}
	
	tl.fromTo(titles, { translateX: "20%", rotateY: 10, opacity: 0 }, 
		{ translateX: "0%", rotateY: -10, opacity: 1, ease: "power1.inOut", duration: 1, stagger: 0.1 }, 0);

		*/


		/*

	const sequence = [
		
	]

	titles.forEach((title, i) => {
		sequence.push([title, 
			{ rotateY: ["10deg", "-10deg"], 
				translateX: ["20%", "0%"], 
				opacity: [0, 1], 
				at: 0, 
				delay: 0, duration: 0 
			}, 0])
	})

	const tl = timeline(sequence, { loop: false, autoplay: false, duration: 1, easing: "ease-in-out" })


	inView(container,
		(info) => {
			//const controls = animate(info.target, { opacity: 1 })
			console.log("in view", info.target)
			tl.play()
			
			// This will fire when the element leaves the viewport
			return (leaveInfo) =>  {
				console.log("out of view", info.target)
				tl.stop()
			}
		}
	)
		*/