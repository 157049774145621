import { Element, html, css } from "../element";
import "./glitching"
import "./button"

const styles = css`
@keyframes pulse {
    0% {
        transform: scale(1);
		opacity: 0.3;
    }

    70% {
        transform: scale(1);
		opacity: 1;
    }

    100% {
        transform: scale(1);
		opacity: 0.3;
    }
}

  :host {
    display: block;
	position: relative;
	
  }

  .self-destruction-warning {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 100;
  }

  .self-destruction-warning .message {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: var(--color-white);
	padding: 32px 64px;
	font-size: 3rem;
	font-weight: bold;
	position: relative;
	z-index: 10;
  }
  .self-destruction-warning .bg {
	background: var(--color-fire);
	border-radius: 24px;
	padding: 24px 32px;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	box-sizing: border-box;
	transform: scale(1);
    animation: pulse 2s infinite;
  }
  .self-destruction-warning div p {
	margin: 0;
	padding: 0;
  }
  .actions {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;
	padding: 24px 0;	
  }
  harmony-button {
	--button-font-size: 1.8rem;
	--button-color: white;
  }
`;

const template = function (elem) {
	const { steps = 10, props = {}} = elem;

	const triggerAlarm = ({ steps }) => {
		return html`<div class="self-destruction-warning">
			<div class="message"><p>Self destructing in <harmony-glitching .repeat="${0}" .speed=${10} 
				.config=${{ text: Array(steps).fill(0).map((n,i) => i+'').reverse() }}></harmony-glitching>...</p>
				<div class="actions">
					<harmony-button variant="outline" @click=${(e)=>elem.cancel()}>Cancel</harmony-button>
					<harmony-button variant="outline" @click=${(e)=>elem.kill()}>Kill</harmony-button>
				</div>
			</div>
		<div class="bg"></div>
		</div>`
	}


  return html`
    ${triggerAlarm({steps})}
  `;
};


export class SelfDestruct extends Element {
  static template = template;
  static styles = styles;

  static properties = {
    steps: { type: Number },
  };

  constructor() {
    super();  
	this.steps = 10; 
	this.timerid = null
  }

  attachedCallback() {

		this.fireEvent({
				name: "destruction-start",
				detail: {
					steps: this.steps
				}
		})
		
		this.timerid = setTimeout(() => {

			
			
			clearTimeout(this.timerid);

			this.selfDestruct()
		}, this.steps * 1000)
  }

  cancel() {
	clearTimeout(this.timerid)

	this.fireEvent({
		name: "cancel",
		detail: {}
	})


	//this.selfDestruct()

  }

  kill() {
	clearTimeout(this.timerid)
	
	this.fireEvent({
		name: "kill",
		detail: {}
	})
	this.selfDestruct()
  }

  selfDestruct() {
	// add animation
	
	this.fireEvent({
		name: "destroy",
		detail: {}
	})

  }

}

customElements.define("harmony-self-destructing", SelfDestruct);
