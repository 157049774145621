import Node from "./node"
import DataArray from "./data-array"

/*
freqIdx += 1
if (freqIdx > (data.length - 1)) {
	freqIdx = 0
}

const dissolvemin = 10
const dissolvemmax = 100
const dir = 1, count = 100


const wfIdx = freqs[freqIdx]


for (let i = 0; i < peaks.length; i++) {
	const d = peaks[i];
	const dr = Util.rand(0, dissolvemmax)/4

	if ((d > dissolvemin) && dr) {
		peaks[i] -= dr;
	}
}

const pl = peaks.length

if (dir === 1) {
	peaks.unshift(data[wfIdx])
	return peaks.pop()

} else if ((dir === 2) || (dir === 3)) {
	const f1 = freqIdx
	const m = args.mid;
	let mid = Math.ceil(((m / 100) * pl)) - 1
	if (mid < 0) { mid = 0; }


	let f2 = f1 + 1

	if (f2 > (args.freq.length - 1)) {
		f2 = 0
	}

	const wf1 = data[freqs[f1]]
	const wf2 = data[freqs[f2]]

	if (dir === 3) {
		peaks.splice(mid, 2)
		peaks.unshift(wf1)
		return peaks.push(wf2)

	} else {
		peaks.splice(mid-1, 0, wf1, wf2)
		peaks.shift()
		return peaks.pop()
	}

} else if (dir === 4) {
	return (() => {
		const result = [];
		for (let fr of Array.from(freqs)) {
			const wf = data[fr];
			const idx = Math.round(Util.rand(0, pl));
			result.push(peaks[idx] = wf);
		}
		return result;
	})();
} else {
	peaks.push(data[wfIdx]);
	return peaks.shift();
}
*/


const ctxMap = new WeakMap()
const workerMap = new WeakMap()

const Plotter = function(worker) {
	return {
		plot(data) {
			if (worker)
				worker.postMessage({ draw: data });
		}
	}
}



class Visualizer {

    constructor(node){

		var plotter

		const { source } = node
		var settings = node.settings

		if (!source || source.name !== 'analyser') {
			this.tick = () => { }
			return this
		}

		const sourceData = source.data
		const sourcePeaks = source.peaks

		const { data, peaks } = node
		var worker, mid, width, height, len, params

		this.reset = () => {
			settings = node.settings
			worker = settings.worker
			mid = settings.mid

			if (worker) {
				width = worker.width
				height = worker.height
				//ctx = canvas && canvas.getContext('2d')
			}

			len = sourceData.length
			params = { mid }

			plotter = Plotter(worker)
		}
		this.reset()

		const drawModes = {
			bars: () => {
				if (!plotter) { return }

				plotter.plot({ data, peaks, params })
			}
		}

		const modes = {
			points: () => {
				peaks.length = data.length = len

				for (let i = 0; i < len; i++) {
					const d = sourceData[i]
					const p = sourcePeaks[i]

					let pt = data[i]
					if (!pt) {
						pt = { x: 0, y: 0, z: 0, value: 0 }
						data[i] = pt
					}

					let peak = peaks[i]
					if (!peak) {
						peak = { x: 0, y: 0, z: 0, value: 0 }
						peaks[i] = peak
					}

					pt.value = d
					peak.value = p
				}

				drawModes.bars()
			}
		}

		var run = (time) => {
			if (!plotter) {
				return
			}

			modes.points()
	    }



		this.tick = () => {
			run()
		}

    }




}





class VisualizerNode extends Node {

	constructor(...args) {
		super(...args)


		this.data = new Array()
		this.peaks = new Array()
		this.settings = {}
	}

	init() {
		const ctx = this.ctx

		this.node = ctx.createGain()
		this.outNode = ctx.createGain()

		const setProps = this.reset = () => {

			this.settings.mid = this.config.mid

			this.getWorker().then(() => {
				if (this.visualizer && this.visualizer.reset)
					this.visualizer.reset()
			})
		}
		setProps()

		this._tick = (time) => {
			if (this.visualizer)
				this.visualizer.tick()

		}

	}

	async getWorker() {
		delete this.settings.worker

		const elid = this.config.canvas && this.config.canvas[0]
		if (elid) {
			const [el, component] = this.parent.site.getComponentById(elid)

			if (component && component.getWorker)
				this.settings.worker = await component.getWorker()

		}
	}


	tick(time) {
		this._tick && this._tick(time)
	}


	get output() {
		return this.outNode
	}
	get input() {
		return this.node
	}

	connect(sources) {
		this.source = sources[0]
		if (this.source) {
			this.source.output.connect(this.input)
		}
		this.visualizer = new Visualizer(this)
	}

}

export default VisualizerNode
