const _avg_ = Symbol()
const _sum_ = Symbol()
class DataArray extends Array {
	get average() {
		return this[_avg_] || 0
	}
	get sum() {
		return this[_sum_] || 0
	}
	set average(a) {
		this[_avg_] = a
	}
	set sum(s) {
		this[_sum_] = s
	}
}

export default DataArray
